import { useEffect } from "react";
import { dispatch } from "../redux";

const useRecipe = slug =>
    useEffect(() => {
        if (!slug) {
            dispatch("recipes/all");
        } else {
            dispatch("recipes/get-by-slug", decodeURI(slug));
        }
    }, [slug]);

export default useRecipe;

import { useEffect } from "react";

const useScrollTag = (refs, urlHash, arrayIsComplete) => {
    useEffect(() => {
        if (refs && window.location.hash) {
            Object.keys(refs.current).forEach(ref => {
                if (window.location.hash.includes(encodeURI(ref))) {
                    refs.current[ref].scrollIntoView({
                        behaviour: "smooth",
                        block: "start",
                        inline: "center"
                    });
                }
            });
        }
    });
};

export default useScrollTag;

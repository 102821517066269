export const colors = {
    white: "#FFF",
    red: "#7A1B1F",
    brown: "#EBE8DF",
    lightGrey: "#707070",
    darkGrey: "#363636",
    orange: "#E27900",
    lightOrange: "#FFA600",
    black: "#2B2522",
    green: "#00681B",
    beige: "#F3F1EC"
};

export const themeFont = size => {
    let themeFont = `
	    font-family: tenso, serif;
	    font-weight: 400;
	    font-style : normal;
	`;
    switch (size) {
        case 18: {
            return (
                themeFont +
                `
            font-size: 18px;
            line-height: 23px;
            `
            );
        }
        case 20: {
            return (
                themeFont +
                `
            font-size: 20px;
            line-height: 26px;
			@media (max-width: 768px) {
				line-height: 28px;
			}
            `
            );
        }
        case 22: {
            return (
                themeFont +
                `
            font-size: 22px;
            line-height: 30px;
            `
            );
        }
        case 24: {
            return (
                themeFont +
                `
            font-size: 24px;
            line-height: 34px;
            `
            );
        }
        case 30: {
            return (
                themeFont +
                `
            font-size: 30px;
            line-height: 35px;
            `
            );
        }
        case 32: {
            return (
                themeFont +
                `
            font-size: 32px;
            line-height: 44px;
            `
            );
        }
        case 42: {
            return (
                themeFont +
                `
            font-size: 42px;
            line-height: 52px;
            `
            );
        }
        case 45: {
            return (
                themeFont +
                `
            font-size: 45px;
            line-height: 46px;
            `
            );
        }
        case 52: {
            return (
                themeFont +
                `
            font-size: 52px;
            line-height: 65px;
            `
            );
        }
        case 60: {
            return (
                themeFont +
                `
            font-size: 60px;
            line-height: 64px;
            `
            );
        }
        case 80: {
            return (
                themeFont +
                `
            font-size: 80px;
            line-height: 91px;
            `
            );
        }
        default:
            return (
                themeFont +
                `
			font-size: 18px;
			line-height: 23px;
			`
            );
    }
};

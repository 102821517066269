import React from "react";
import styled from "styled-components";
import { themeFont } from "../const";
import connect from "react-redux/es/connect/connect";
import { useInView } from "react-hook-inview";
import useGlobals from "../hooks/useGlobals";
import useProduct from "../hooks/useProduct";
import useArticle from "../hooks/useArticle";
import useRecipe from "../hooks/useRecipe";
import { Grid, Row } from "../components/Grid";
import ProductsList from "../modules/partials/ProductsList";

const SearchPage = ({
    slug,
    productState,
    recipeState,
    pageState,
    articleState
}) => {
    useGlobals();
    useProduct();
    useArticle();
    useRecipe();
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });

    let productsSearchResults = [];

    productState &&
        Object.keys(productState).forEach(key => {
            const product = productState[key];
            let searchable =
                product.title.toLowerCase() +
                " " +
                product.category?.slug?.current;
            if (searchable.includes(decodeURI(slug).toLowerCase())) {
                productsSearchResults = [
                    ...productsSearchResults,
                    { ...product }
                ];
            }
        });

    let recipeSearchResults = [];

    recipeState &&
        Object.keys(recipeState).forEach(key => {
            const recipe = recipeState[key];
            let searchable =
                recipe.title.toLowerCase() +
                " " +
                recipe.category?.slug?.current;
            if (searchable.includes(decodeURI(slug).toLowerCase())) {
                recipeSearchResults = [...recipeSearchResults, { ...recipe }];
            }
        });

    let articleSearchResults = [];

    articleState &&
        Object.keys(articleState).forEach(key => {
            const article = articleState[key];
            //perform a search on the searchable string, this returns -1 if no match is found
            if (
                article.searchable.search(decodeURI(slug).toLowerCase()) !== -1
            ) {
                articleSearchResults = [
                    ...articleSearchResults,
                    { ...article }
                ];
            }
        });

    console.log("Article searchresults: ", articleSearchResults);

    return (
        <Container
            className={
                "module module-event-list " + (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper>
                {recipeSearchResults.length < 1 &&
                    productsSearchResults.length < 1 &&
                    articleSearchResults.length < 1 && (
                        <CategoryWrapper>
                            <Category>
                                <h2>Ingen treff</h2>
                            </Category>
                        </CategoryWrapper>
                    )}
                {productsSearchResults.length > 0 && (
                    <CategoryWrapper type={"product"}>
                        <Category>
                            <h2>Produkter</h2>
                            <Grid>
                                <Row>
                                    <ProductsList
                                        products={productsSearchResults}
                                        type="product"
                                        title="Produkter"
                                    />
                                </Row>
                            </Grid>
                        </Category>
                    </CategoryWrapper>
                )}
                {recipeSearchResults.length > 0 && (
                    <CategoryWrapper>
                        <Category>
                            <h2>Oppskrifter</h2>
                            <Grid>
                                <Row>
                                    <ProductsList
                                        products={recipeSearchResults}
                                        type="recipe"
                                        title="Oppskrifter"
                                    />
                                </Row>
                            </Grid>
                        </Category>
                    </CategoryWrapper>
                )}

                {articleSearchResults.length > 0 && (
                    <CategoryWrapper>
                        <Category>
                            <h2>Artikler</h2>
                            <Grid>
                                <Row>
                                    <ProductsList
                                        products={articleSearchResults}
                                        type="article"
                                        title="Artikler"
                                    />
                                </Row>
                            </Grid>
                        </Category>
                    </CategoryWrapper>
                )}
            </ContentWrapper>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            pageState: state.pages,
            recipeState: state.recipe,
            productState: state.product,
            articleState: state.articles
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(SearchPage);

const Container = styled.div`
    width: 100%;
    &:after {
        content: "";
        clear: both;
        display: table;
    }
`;

const ContentWrapper = styled.div`
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const CategoryWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

const Category = styled.div`
    width: 100%;
    max-width: 1366px;
    margin: 0 82px;
    h2 {
        ${themeFont(60)}
        margin-bottom: 15px;
    }
    p {
        ${themeFont(18)}
        max-width: 700px;
    }
    @media (max-width: 768px) {
        h2 {
            margin-bottom: 25px;
        }
        margin: 0 25px 0 25px;
    }
`;

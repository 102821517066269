import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import useScroll from "../../hooks/useScroll";

const RefWrapper = React.forwardRef((props, ref) => (
    <header ref={ref}>
        <Header {...props} />
    </header>
));

let headerHeight = null;

const MasterLayout = ({ children }) => {
    const [menuState, setMenuState] = useState(false);
    const { y, direction } = useScroll();
    const header = useRef(null);

    useEffect(() => {
        //as of release 95 is the current height of the header, so we're using it as a failsafe.
        headerHeight = header.current.scrollheight || 95;
    }, []);

    let animateFadeOut = y > headerHeight;

    return (
        <Container className={menuState && "menu-open"}>
            <RefWrapper
                ref={header}
                menuState={menuState}
                setMenuState={setMenuState}
                animateFadeOut={animateFadeOut}
                direction={direction}
            />
            <PageContent animateFadeOut={animateFadeOut}>
                {children}
            </PageContent>
            <Footer />
        </Container>
    );
};

export default MasterLayout;

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    &.menu-open {
        @media (max-width: 768px) {
            height: 100vh;
            overflow-y: hidden;
        }
    }
`;

const PageContent = styled.main`
    display: flex;
    flex: 1;
    min-height: calc(100vh - 100px);
    ${({ animateFadeOut }) =>
        animateFadeOut
            ? `
		margin-top: ${headerHeight}px;
	`
            : ``}
    @media (max-width: 768px) {
        overflow: hidden;
    }
`;

import styled, { css } from "styled-components";
export const defaultGutter = 32;
const colWidth = 100 / 12;
export const Grid = styled.div`
    * {
        box-sizing: border-box;
    }
    width: 100%;
    position: relative;
`;
export const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% + ${defaultGutter}px);
    flex-wrap: wrap;
    position: relative;
    left: -${defaultGutter / 2}px;
`;
export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => colWidth * props.span || 1}%;
    min-height: 384px;
	${props => (props.noMinWidth ? null : "min-width: 384px;")}
    ${props =>
        props.gutterWidth
            ? `
		padding-left: ${props.gutterWidth / 2}px;
		padding-right: ${props.gutterWidth / 2}px;
	`
            : `
		padding-left: ${defaultGutter / 2}px;
		padding-right: ${defaultGutter / 2}px;
	`}
    ${props =>
        props.offset &&
        css`
            margin-left: ${props.offset * colWidth}%;
        `};
    @media (max-width: 768px) {
        width: ${props => colWidth * props.mobileSpan || 1}%;
    }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { themeFont, colors } from "../const";
import { SanityParser } from "./controllers/SanityParser";
import { A } from "hookrouter";
import { useInView } from "react-hook-inview";
import { Grid, Row, Column } from "../components/Grid";
import Image from "../components/image";

const ProductGrid = ({ text, title, products, backgroundColor }) => {
    const [gridLimitState] = useState(6);
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            backgroundColor={backgroundColor}
            className={
                "product-grid module module-event-list " +
                (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper>
                <IntroWrapper>
                    <h2>{title}</h2>
                    {text}
                </IntroWrapper>
                <Grid>
                    <Row>
                        {!products && <div>Loading products...</div>}
                        {!!products &&
                            Object.keys(products)
                                .map(key => {
                                    const product = products[key];
                                    const parsed = SanityParser({
                                        _type: "product",
                                        ...product
                                    });
                                    return (
                                        <Column
                                            key={product._id}
                                            span={4}
                                            mobileSpan={12}
                                            noMinWidth
                                        >
                                            <Product
                                                href={
                                                    "../produkter/" +
                                                    parsed.link
                                                }
                                            >
                                                <ImageWrapper>
                                                    <Image
                                                        alt={title}
                                                        src={parsed.image}
                                                        source={parsed.imageObj}
                                                        width={434}
                                                    />
                                                </ImageWrapper>
                                                <ProductInfo>
                                                    <p className="category-title">
                                                        {parsed.category}
                                                    </p>
                                                    <p className="product-title">
                                                        {parsed.title}
                                                    </p>
                                                    <p className="product-weight">
                                                        {parsed.weight}
                                                    </p>
                                                </ProductInfo>
                                            </Product>
                                        </Column>
                                    );
                                })
                                .slice(0, gridLimitState)}
                    </Row>
                </Grid>
                <Button href={"../produkter"}>
                    SE HELE VÅRT PRODUKTSORTIMENT
                </Button>
            </ContentWrapper>
        </Container>
    );
};

export default ProductGrid;

const Container = styled.div`
    ${props =>
        props.backgroundColor ? `background-color: ${colors.brown};` : ""}
    position: relative;
    min-height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
`;
const ContentWrapper = styled.div`
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    max-width: 1366px;
    margin: 0 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
const IntroWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 70px 0;
    max-width: 400px;
    color: ${colors.black};
    h2,
    p {
        flex: 0;
        text-align: center;
        margin: 0;
    }
    h2 {
        ${themeFont(30)}
    }
    p {
        ${themeFont(18)}
    }
`;
const Product = styled(A)`
    text-decoration: none;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 100px;
`;
const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    p {
        margin-block-start: 0;
        margin-block-end: 0;
        color: ${colors.darkGrey};
    }
    .category-title {
        ${themeFont(18)}
    }
    .product-title {
        ${themeFont(24)}
        text-decoration: underline;
    }
    .product-weight {
        ${themeFont(18)}
    }
`;
const ImageWrapper = styled.div`
    align-self: center;
    img {
        max-height: 220px;
        object-fit: contain;
        width: 100%;
        height: auto;
    }
`;

const Button = styled.a`
    ${themeFont(18)}
    background-color: ${colors.green};
	border-radius: 31px;
    color: white;
    padding: 20px 30px;
    cursor: pointer;
    text-decoration: none;
	@media (max-width: 768px) {
		text-align: center;
	}
`;

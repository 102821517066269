import React, { useState, useEffect } from "react";

export default function useWindowSize(targetWidth) {
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth <= targetWidth && !isMobile) {
            setIsMobile(true);
        } else if (window.innerWidth >= targetWidth) {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
}

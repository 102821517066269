const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "articles/create":
            return { ...payload };
        case "articles/set":
            let data = [];
            payload.forEach(article => {
                if (article.slug) {
                    data[article.slug.current] = article;
                }
            });
            return { ...state, ...data };
        case "articles/update":
            return { ...state, ...payload };
        case "articles/clear":
        case "REDUX/CLEAR":
            return { ...defaultState };
        default:
            return state;
    }
};

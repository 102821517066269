import React from "react";
import styled from "styled-components";
import { colors } from "../../const";
import { useInView } from "react-hook-inview";

const ImageAndText = ({ image, background_bottom, backgroundColor }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            className={
                "module module-event-list " + (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper>
                <ImageBlock>
                    <ImageWrapper>
                        <img alt="" src={image} />
                    </ImageWrapper>
                    {backgroundColor && (
                        <BackgroundBlock bottom={background_bottom} />
                    )}
                </ImageBlock>
            </ContentWrapper>
        </Container>
    );
};

export default ImageAndText;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContentWrapper = styled.div`
    z-index: 1;
    display: flex;
    width: 100%;
    max-width: 1366px;
    margin: 0 82px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const ImageBlock = styled.div`
    min-width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
`;

const ImageWrapper = styled.div`
    flex: 1;
    padding: 55px 43px 55px 0;
    display: flex;
    z-index: 1;
    justify-content: center;
    img {
        object-fit: contain;
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const BackgroundBlock = styled.div`
	${({ bottom }) =>
        bottom
            ? `
		bottom: 0;
		left: 20%;
		max-height: calc(100% * 0.75);
	`
            : `
		top: 0;
		max-height: calc(100% * 0.55);
	`}
	background-color: ${colors.brown};
    position: absolute;
    width: calc(100% * 0.84);
	height: 100%;
	z-index: 0;
`;

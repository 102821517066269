const getImageDimensionsFromUrl = image => {
    // get dimensions of source image from url
    if (!image) return { width: 0, height: 0 };
    const dimensions = image.match(/-(.*?)\./)[1].split("x");
    const width = Number(dimensions[0]);
    const height = Number(dimensions[1]);
    //check if image should be treated as a tall image or a wide image
    return { width: width, height: height };
};

export default getImageDimensionsFromUrl;

import sanityClient from "@sanity/client";
import reactEnv from "./reactEnv";

const client = sanityClient({
    projectId: reactEnv("sanity_project_id"),
    dataset: reactEnv("sanity_dataset"),
    token: reactEnv("sanity_token"),
    useCdn: true
});

export default client;

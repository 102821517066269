import { put, takeEvery, select } from "redux-saga/effects";
import Api from "../../services/Api";
import stripSlug from "../../utils/stripSlug";

const api = new Api();

function* getAllProducts() {
    try {
        const { hasAllProducts } = yield select(state => ({
            products: state.products,
            hasAllProducts: state.global.hasAllProducts
        }));

        if (hasAllProducts) return;

        const response = yield api.fetcher("getAllProducts");
        let allergies = {};
        allergies["products"] = {};
        let categories = {};
        categories["products"] = {};
        response.products.forEach(product => {
            if (product.allergies) {
                product.allergies.forEach(allergy => {
                    allergies["products"][allergy.title] = allergy.title;
                });
            }
            categories["products"][product.category.slug.current] =
                product.category;
        });
        yield put({
            type: "category/set",
            payload: categories
        });
        yield put({
            type: "allergy/set",
            payload: allergies
        });
        yield put({
            type: "products/set",
            payload: response.products
        });
        yield put({
            type: "global/update",
            payload: response.global
        });
        yield put({
            type: "global/update",
            payload: { hasAllProducts: true }
        });
    } catch (e) {
        yield put({
            type: "site/get-products/failed"
        });
    }
}

function* getProduct(action) {
    try {
        const slug = stripSlug(action.payload);

        // Check if we have the product cached in redux
        let product = yield select(
            state => state.product && state.product[slug]
        );

        if (product) {
            yield put({
                type: "products/found-in-cache",
                product
            });
            return;
        }

        const response = yield api.fetcher("getProductBySlug", slug);
        if (response) {
            yield put({
                type: "products/set",
                payload: response.products
            });
        }
        yield put({
            type: "global/update",
            payload: response.global
        });
    } catch (e) {
        yield put({
            type: "page/not-found"
        });
    }
}

export default [
    takeEvery("products/all", getAllProducts),
    takeEvery("products/get-by-slug", getProduct)
];

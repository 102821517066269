import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import imageBuilder from "../../utils/imageBuilder";
import Image from "../../components/image";

export const SanityParser = module => {
    switch (module._type) {
        case "menu":
            return {
                image: imageBuilder(module.image.asset)
                    .quality(80)
                    .url()
                    .toString(),
                imageObj: module.image.asset,
                links: module.menuItems.map(item => {
                    return {
                        ...link(item)
                    };
                })
            };
        case "footer":
            return {
                image: imageBuilder(module.image.asset)
                    .quality(80)
                    .url()
                    .toString(),
                imageObj: module.image.asset,
                nav_left: module.footer.nav_left.map(item => {
                    return {
                        ...link(item)
                    };
                }),
                nav_right: module.footer.nav_right.map(item => {
                    return {
                        ...link(item)
                    };
                }),
                address: text(module.contact.address),
                email: module.contact.email,
                phone: module.contact.telephone
            };
        case "article":
            const firstModule = module.modules[0];
            return {
                image:
                    firstModule.media &&
                    firstModule.media[0] &&
                    firstModule.media[0]?._type !== "video" &&
                    imageBuilder(firstModule.media[0])
                        .quality(80)
                        .width(434)
                        .url()
                        .toString(),
                title: module.title,
                link: module.slug && module.slug.current
            };
        case "page":
            //find modules that contains an image and use the first one for our thumbnail
            let image = module?.modules?.filter(module => module.image);
            return {
                image:
                    module.modules &&
                    image[0].image &&
                    imageBuilder(image[0].image.asset)
                        .quality(80)
                        .width(434)
                        .url()
                        .toString(),
                title: module.title,
                link: module.slug && module.slug.current
            };
        case "recipe":
            return {
                image:
                    module.image &&
                    imageBuilder(module.image.asset)
                        .quality(80)
                        .width(346)
                        .url()
                        .toString(),
                imageObj: module.image && module.image.asset,
                largeImage:
                    module.image &&
                    imageBuilder(module.image.asset)
                        .quality(80)
                        .url()
                        .toString(),
                largeImageObj: module.image && module.image.asset,
                title: module.title,
                intro: text(module.intro),
                link: module.slug && module.slug.current,
                allergies: module.allergies,
                ingredients: module.ingredients,
                category: module.category.title,
                portions: module.portions,
                preparation: module.preparation && text(module.preparation),
                preparation_image: imageBuilder(module.preparation_image),
                preparationImageObj: module.preparation_image
            };
        case "product":
            return {
                image:
                    module.image &&
                    imageBuilder(module.image.asset)
                        .quality(80)
                        .width(434)
                        .url()
                        .toString(),
                imageObj: module.image && module.image.asset,
                largeImage:
                    module.image &&
                    imageBuilder(module.image.asset)
                        .quality(80)
                        .url()
                        .toString(),
                largeImageObj: module.image && module.image.asset,
                title: module.title,
                product_name: module.product_name,
                intro: text(module.intro),
                category: module.category && module.category.title,
                weight: module.weight,
                link: module.slug && module.slug.current,
                allergies: module.allergies,
                information: text(module.information),
                preparation: text(module.preparation),
                preparation_image: imageBuilder(module.preparation_image),
                preparationImageObj: module.preparation_image,
                ingredients: text(module.ingredients),
                nutritional: module.nutritional,
                nutritional_secondary: module.nutritional_secondary,
                nutrition_title: module.nutrition_title,
                nutrition_title_secondary: module.nutrition_title_secondary,
                energy: module.energy,
                energy_secondary: module.energy_secondary
            };
        case "productGrid":
            return {
                title: module.title,
                text: text(module.text),
                products: module.products
            };
        case "productFilter":
            return {
                title: module.title,
                text: text(module.text),
                categories: module.categories
            };
        case "recipeFilter":
            return {
                title: module.title,
                text: text(module.text),
                categories: module.categories
            };
        case "backgroundImageAndVideo":
            return {
                image:
                    module.image &&
                    imageBuilder(module.image)
                        .url()
                        .toString(),
                media_image:
                    module.media &&
                    module.media[0]._type !== "video" &&
                    imageBuilder(module.media[0])
                        .quality(80)
                        .url()
                        .toString(),
                video:
                    module.media &&
                    module.media[0]._type === "video" &&
                    module.media[0],
                link: module.link && link(module.link),
                text: text(module.text),
                title: module.title,
                anchor: module.anchor
            };
        case "imageAndText":
            return {
                image:
                    module.media &&
                    module.media[0] &&
                    module.media[0]._type !== "video" &&
                    imageBuilder(module.media[0])
                        .quality(75)
                        .url()
                        .toString(),
                imageObj:
                    module.media &&
                    module.media[0] &&
                    module.media[0]._type !== "video" &&
                    module.media[0],
                link: module.link && link(module.link),
                text: text(module.text),
                title: module.title,
                video:
                    module.media &&
                    module.media[0]?._type === "video" &&
                    module.media[0],
                reverse: module.reverse,
                layout: module.layout,
                playerBackgrounded: module.playerBackgrounded,
                backgroundColor: module.color,
                background_bottom: module.background_layout,
                double: module.double,
                secondary_image:
                    module.secondary_image &&
                    imageBuilder(module.secondary_image)
                        .quality(80)
                        .url()
                        .toString(),
                secondaryImageObj: module.secondary_image,
                anchor: module.anchor
            };
        case "videoAndText":
            return {
                text: text(module.text),
                title: module.title,
                link: module.link && link(module.link),
                videoUrl: module.video,
                reverse: module.reverse,
                layout: module.layout,
                backgroundColor: module.color,
                anchor: module.anchor,
                isBackgroundVideo: module.isBackgroundVideo === "true",
                isVertical: module.vertical
            };
        case "articleImage":
            return {
                image:
                    module.image &&
                    imageBuilder(module.image)
                        .quality(80)
                        .url()
                        .toString(),
                backgroundColor: module.color,
                background_bottom: module.background_layout
            };
        case "introImageAndText":
            const getMobileVideo = module => {
                if (module.mobileMedia) {
                    if (module.mobileMedia[0]) {
                        if (module.mobileMedia[0]._type === "video") {
                            return module.mobileMedia[0];
                        }
                        return null;
                    } else {
                        return null;
                    }
                }
                return null;
            };
            const getMobileImage = module => {
                if (module.mobileMedia) {
                    if (module.mobileMedia[0]) {
                        if (module.mobileMedia[0]._type !== "video") {
                            return imageBuilder(module.mobileMedia[0])
                                .url()
                                .toString();
                        }
                        return null;
                    } else {
                        return null;
                    }
                }
                return null;
            };
            return {
                media_image:
                    module.media &&
                    module.media[0] &&
                    module.media[0]?._type !== "video" &&
                    imageBuilder(module.media[0])
                        .width(1440)
                        .quality(75)
                        .url()
                        .toString(),
                mediaImageObj:
                    module.media &&
                    module.media[0] &&
                    module.media[0]?._type !== "video" &&
                    module.media[0],
                media_image_small:
                    module.media &&
                    module.media[0] &&
                    module.media[0]?._type !== "video" &&
                    imageBuilder(module.media[0])
                        .quality(80)
                        .width(720)
                        .url()
                        .toString(),
                video:
                    module.media &&
                    module.media[0]?._type === "video" &&
                    module.media[0],
                mobileImage: getMobileImage(module),
                mobileVideo: getMobileVideo(module),
                title: module.title,
                text: text(module.text),
                image_title: module.image_title,
                image_text: text(module.image_text),
                playerBackgrounded: module.playerBackgrounded,
                image_text_link:
                    module.image_text_link && link(module.image_text_link),
                image_alt_text: module.image_alt_text,
                bottom_left_text: module.bottom_left_text,
                reverse: module.reverse,
                background_color: module.background_color
            };
        case "richTextAndSidebar":
            return {
                title: module.title,
                text: text(module.text),
                sidebar: !module.disable_sidebar,
                anchor: module.anchor
            };
        case "textAndText": {
            return {
                text: module.text,
                anchor: module.anchor
            };
        }
        case "peopleGrid": {
            return {
                title: module.title,
                people: module.people
            };
        }
        case "imageLinkAndSidebar": {
            return {
                title: module.title,
                image: module.image
                    ? imageBuilder(module.image)
                          .quality(80)
                          .url()
                          .toString()
                    : false,
                sidebar: !module.disable_sidebar,
                link: module.link && link(module.link),
                anchor: module.anchor
            };
        }
        case "person": {
            return {
                email: module.email,
                image: module.image
                    ? imageBuilder(module.image)
                          .quality(80)
                          .url()
                          .toString()
                    : false,
                name: module.name,
                phone: module.phone,
                title: module.title
            };
        }
        case "form": {
            return {
                title: module.title,
                intro: text(module.intro),
                receiver: module.receiver,
                fields: module.fields,
                anchor: module.anchor
            };
        }
        default:
            console.log("Missed all the conditions for content parsing");
            break;
    }
};

const large_text = props => (
    <span className="large_text">{props.children}</span>
);

const text = text => {
    if (!text) return;
    return (
        <BlockContent
            blocks={text}
            serializers={{
                marks: {
                    large_text,
                    link: ({ mark, children }) => {
                        const { href } = mark;
                        return (
                            <a
                                href={href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {children}
                            </a>
                        );
                    }
                },
                types: {
                    image: props => {
                        return (
                            <figure>
                                <Image source={props.node} width={700} />
                            </figure>
                        );
                    }
                }
            }}
        />
    );
};

const link = item => {
    const link = item[0] ? item[0] : item;

    const anchor = link.anchor
        ? "#" + encodeURI(link.anchor.toLowerCase())
        : "";

    switch (link._type) {
        case "internalLink":
            let sub = "";
            switch (link.internalLink._type) {
                case "product":
                    sub = "produkter/";
                    break;
                case "recipe":
                    sub = "oppskrifter/";
                    break;
                default:
                    sub = "";
                    break;
            }
            return {
                url: sub + link.internalLink.slug.current + anchor,
                name: link.name,
                blank: !!link.blank !== false,
                anchor: !!link.anchor !== false
            };
        case "externalLink":
            return {
                url: link.url + anchor,
                name: link.name,
                blank: !!link.blank !== false,
                external: true
            };
        default:
            console.log("Missed both conditions for parsing Links");
            break;
    }
};

const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "recipes/set":
            let data = [];
            action.payload.forEach(recipe => {
                if (recipe.slug) data[recipe.slug.current] = recipe;
            });
            return { ...state, ...data };
        case "recipes/update":
            return { ...state, ...payload };
        case "recipes/clear":
        case "REDUX/CLEAR":
            return { ...defaultState };
        default:
            return state;
    }
};

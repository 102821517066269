import React from "react";
import styled from "styled-components";
import { themeFont, colors } from "../../const.js";
import ReactPlayer from "react-player";
import { useInView } from "react-hook-inview";
import useWindowSize from "../../hooks/useWindowSize";
import Image from "../../components/image";

const VideoComponent = ({
    url,
    playerConfig,
    imageTitle,
    imageText,
    bottomLeftText,
    title,
    imageTextLink
}) => {
    return (
        <VideoWrapper>
            <Video
                url={url}
                height={"100%"}
                width={"100%"}
                config={playerConfig}
            />
            {(imageTitle || imageText) && (
                <ImageText
                    bottom_left_text={bottomLeftText}
                    titleExists={!!title}
                >
                    <div
                        className={
                            (imageTextLink ? "text-padding" : "") +
                            " text-container "
                        }
                    >
                        <h2
                            dangerouslySetInnerHTML={{
                                __html: imageTitle
                            }}
                        />
                        {imageTextLink && (
                            <Button href={imageTextLink.url}>
                                {imageTextLink.name}
                            </Button>
                        )}
                    </div>
                </ImageText>
            )}
        </VideoWrapper>
    );
};

const IntroImageAndText = ({
    media_image,
    mediaImageObj,
    video,
    image,
    playerBackgrounded,
    mobileImage,
    mobileVideo,
    link,
    text,
    title,
    image_title,
    image_text,
    image_alt_text,
    bottom_left_text,
    reverse,
    background_color,
    image_text_link
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const isMobile = useWindowSize(768);

    console.log("I got rendered");

    const playerConfig = {
        vimeo: {
            playerOptions: {
                controls: !playerBackgrounded,
                autoplay: playerBackgrounded,
                background: playerBackgrounded,
                muted: playerBackgrounded,
                byline: 0,
                title: 0,
                loop: playerBackgrounded
            },
            preloading: true
        }
    };

    const renderMediaText = () => {
        if (image_title || image_text) {
            return (
                <ImageText
                    bottom_left_text={bottom_left_text}
                    titleExists={!!title}
                >
                    <div
                        className={
                            (image_text_link ? "text-padding" : "") +
                            " text-container "
                        }
                    >
                        <h2
                            dangerouslySetInnerHTML={{
                                __html: image_title
                            }}
                        />
                        {image_text}
                        {image_text_link && (
                            <Button href={image_text_link.url}>
                                {image_text_link.name}
                            </Button>
                        )}
                    </div>
                </ImageText>
            );
        }
    };

    const renderMedia = () => {
        if (isMobile) {
            if (mobileImage || mobileVideo) {
                if (mobileImage) {
                    return (
                        <ImageWrapper>
                            <img
                                alt={image_alt_text || "Header image"}
                                src={mobileImage}
                            />
                            {renderMediaText()}
                        </ImageWrapper>
                    );
                }
                return (
                    <VideoComponent
                        url={mobileVideo?.name}
                        playerConfig={playerConfig}
                        imageText={image_text}
                        imageTextLink={image_text_link}
                        imageTitle={image_title}
                        title={title}
                        bottomLeftText={bottom_left_text}
                    />
                );
            }
            if (media_image) {
                return (
                    <ImageWrapper>
                        <Image
                            alt="Media"
                            src={media_image}
                            source={mediaImageObj}
                        />
                        {renderMediaText()}
                    </ImageWrapper>
                );
            }
            if (video) {
                return (
                    <VideoComponent
                        url={video?.name}
                        playerConfig={playerConfig}
                        imageText={image_text}
                        imageTextLink={image_text_link}
                        imageTitle={image_title}
                        title={title}
                        bottomLeftText={bottom_left_text}
                    />
                );
            }
        } else if (!isMobile && (media_image || video)) {
            if (media_image) {
                return (
                    <ImageWrapper>
                        <Image
                            alt="Media"
                            src={media_image}
                            source={mediaImageObj}
                        />
                        {renderMediaText()}
                    </ImageWrapper>
                );
            }
            if (video) {
                return (
                    <VideoComponent
                        url={video.name}
                        playerConfig={playerConfig}
                        imageText={image_text}
                        imageTextLink={image_text_link}
                        imageTitle={image_title}
                        title={title}
                        bottomLeftText={bottom_left_text}
                    />
                );
            }
            return null;
        }
    };

    return (
        <Container
            className={
                "module module-event-list intro-image-and-text " +
                (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper reverse={reverse}>
                {(title || text) && (
                    <TopWrapper>
                        <Top
                            image={!!image}
                            className={reverse ? "reverse" : ""}
                            background_color={background_color}
                        >
                            <div className="background-block">
                                <h1
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                                {text}
                            </div>
                        </Top>
                    </TopWrapper>
                )}
                {renderMedia()}
            </ContentWrapper>
        </Container>
    );
};

export default IntroImageAndText;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
`;

const ContentWrapper = styled.div`
    ${({ reverse }) =>
        reverse
            ? `
		flex-direction: column-reverse;
    `
            : `
		flex-direction: column;
	`}
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
`;
const TopWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
const Top = styled.div`
    max-width: 1366px;
    width: 100%;
	margin-top: 85px;
	h1{
        ${themeFont(60)}
        margin: 0 0 20px 0;
    }
    p{
        max-width: 800px;
        margin: 0 80px 20px 0;
        padding: 0;
        ${themeFont(30)}
        color: ${colors.lightGrey};
    }
	&.reverse{
		margin-bottom: 0;
		margin-top: 36px;
	}
	${({ background_color }) =>
        background_color
            ? `
		max-width: 1600px;
		.background-block{
			background-color: ${colors.lightOrange};
			display: inline-flex;
			padding: 40px 0 40px 82px;
			flex-direction: column;
			h1{
				color: ${colors.white};
			}
			p{
				color: ${colors.black};
				${themeFont(18)};
			}
		}
		@media (max-width: 768px) {
			h1{
				${themeFont(45)}
			}
			p{
				${themeFont(18)}
			}
			.background-block{
				padding: 25px;
				margin-bottom: 0;
			}
		}
	`
            : `
		h1{
			color: ${colors.orange};
		}
		.background-block{
			margin: 0 82px;
		}
		@media (max-width: 768px) {
			h1{
				${themeFont(45)}
			}
			p{
				${themeFont(18)}
			}
			.background-block{
				margin: 0 25px 0 25px;
			}
		}

	`}
	${({ image }) =>
        image
            ? `
		margin-bottom: 46px;
		.background-block{
			margin-bottom: 30px;
		}
		@media (max-width: 768px) {
			margin-bottom: 25px;
		}
	`
            : `

	`}
	@media (max-width: 768px) {
		h1, h2{
			${themeFont(42)}
		}
		p{
			${themeFont(18)}
			margin: 0 0 20px 0;
		}
		margin-top: 25px;
	}
`;
const ImageText = styled.div`
    color: ${colors.lightGrey};
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    h2 {
        ${themeFont(80)}
        word-wrap: break-word;
    }
    p {
        ${themeFont(30)}
    }
    .text-container {
        max-width: 1366px;
        width: 100%;
        &.text-padding {
            p {
                padding-bottom: 25px;
            }
        }
    }
    ${({ bottom_left_text }) =>
        bottom_left_text
            ? `
		justify-content: flex-end;
		align-items: center;
		color: ${colors.white};
		text-align: left;
		h2, p{
			margin-left: 82px;
		}
		h2{
			max-width: 625px;
			margin-bottom: 24px;
		}
	    p {
			max-width: 800px;
			margin-bottom: 120px;
	    }
		@media (max-width: 768px) {
			h2,p{
				margin-left: 0;
			}
			h2{
				margin-top: 0;
				color: ${colors.orange};
			}
			p{
				margin-bottom: 25px;
				color: ${colors.lightGrey};
			}
		}
	`
            : `
		justify-content: center;
		align-items: center;
		h2 {
	        color: ${colors.orange};
	        margin: 0 0 20px 0;
	    }
	    p {
	        margin: 0 0 20px 0;
	        padding: 0;
	    }
	`}
    @media (max-width: 768px) {
        position: relative;
        width: unset;
        order: ${({ titleExists }) => (titleExists ? 2 : -1)};
        h1,
        h2 {
            ${themeFont(45)}
        }
        p {
            ${themeFont(18)}
        }
        .text-container {
            width: unset;
            margin: 25px 25px 0;
            &.text-padding {
                margin-bottom: 50px;
            }
        }
    }
`;
const ImageWrapper = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
    position: relative;
    max-width: 1600px;
    img {
        width: 100%;
        margin: 0;
        padding: 0;
        object-fit: contain;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const VideoWrapper = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
    max-width: 1600px;
    padding-top: 56.25%;
    position: relative;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Video = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const Button = styled.a`
    ${themeFont(18)}
    background-color: ${colors.green};
	border-radius: 31px;
    color: white;
    padding: 20px 30px;
    cursor: pointer;
    text-decoration: none;
	@media (max-width: 768px) {
		text-align: center;
	}
`;

import React from "react";
import Routes from "./Routes";
import MasterLayout from "./components/layout/MasterLayout";
import { store } from "./redux";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import Helmet from "react-helmet";

ReactGA.initialize("UA-176561784-1");
ReactGA.pageview(window.location.pathname);

const App = () => {
    return (
        <>
            <Helmet>
                <title>Solvinge</title>
                <meta
                    name="description"
                    content="Solvinge er leverandør av Hubbard-produkter, kyllingen som har det bedre. Merkevaren Solvinge produseres av Norsk Kylling AS, og selges hos REMA 1000."
                />
            </Helmet>
            <Provider store={store}>
                <MasterLayout>
                    <Routes />
                </MasterLayout>
            </Provider>
        </>
    );
};

export default App;

import React, { useState } from "react";
import styled from "styled-components";
import { themeFont, colors } from "../const";
import { navigate } from "hookrouter";
import useFocus from "../hooks/useFocus";

const goToSearch = searchQuery => {
    if (searchQuery) navigate("/search/" + searchQuery);
};

const Search = ({ isOpen, closeSearchBar, setMenuState }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [inputRef, setInputFocus] = useFocus();

    if (isOpen) setInputFocus();
    return (
        <Container isOpen={isOpen} action="">
            <input
                onKeyPress={e => {
                    if (e.key === "Enter" && searchQuery.length > 2) {
                        goToSearch(searchQuery);
                        closeSearchBar();
                        setMenuState(false);
                    }
                }}
                onFocus={e => e.currentTarget.select()}
                ref={inputRef}
                className="search-query"
                type="search"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                placeholder="Tast inn søkeord..."
            />
            <button
                className="submit-search"
                onClick={() => {
                    goToSearch(searchQuery);
                    closeSearchBar();
                }}
            >
                Søk
            </button>
        </Container>
    );
};

export default Search;

const Container = styled.form`
    display: flex;
    width: 100%;
    max-width: 1366px;
    margin: 0 82px;
    justify-content: space-between;
    input.search-query {
        all: unset;
        color: ${colors.black};
        max-width: 800px;
        width: 100%;
        opacity: 0;
        ${themeFont(52)};
        &::placeholder {
            color: ${colors.black};
        }
        @media (min-width: 768px) {
            //remove webkit cancel button from desktop
            &::-webkit-search-cancel-button {
                display: none;
            }
        }
        @media (max-width: 768px) {
            ${themeFont(18)}
            text-align: center;
        }
    }
    button.submit-search {
        border-width: 0;
        ${themeFont(32)};
        background-color: ${colors.lightOrange};
        color: ${colors.white};
        width: 100%;
        max-width: 180px;
        @media (max-width: 768px) {
            display: none;
        }
    }
    ${({ isOpen }) =>
        isOpen
            ? `
		input.search-query {
			transition: opacity 0.2s ease-in;
			opacity: 1;
		}
	`
            : ``}
`;

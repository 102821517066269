import React from "react";
import styled from "styled-components";
import { themeFont, colors as color } from "../../const";
import { A } from "hookrouter";

export const arrow = color => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6.299"
        height="9.769"
        viewBox="0 0 6.299 9.769"
    >
        <path
            id="Path_39"
            data-name="Path 39"
            d="M819.6,1500.276l4.177,4.177-4.177,4.177"
            transform="translate(-818.892 -1499.569)"
            fill="none"
            stroke={color}
            strokeWidth="2"
        />
    </svg>
);

export const LinkButton = (link, backgroundColor) => {
    if (link.anchor || link.external || link.blank) {
        return (
            <LinkA
                className={backgroundColor && "colored"}
                href={link.url}
                target={link.blank ? "_blank" : ""}
            >
                <div>
                    {link.name} <span>{arrow}</span>
                </div>
            </LinkA>
        );
    }
    return (
        <Link
            className={backgroundColor && "colored"}
            href={link.url}
            target={link.blank ? "_blank" : ""}
        >
            <div>
                {link.name} <span>{arrow}</span>
            </div>
        </Link>
    );
};

const Link = styled(A)`
    ${themeFont(22)}
    color: ${color.green};
    display: inline-block;
    padding: 5px 25px 5px 0;
	text-decoration: underline;
    svg{
        margin-left: 7px;
    }
    &:hover{
		text-decoration: none;
		svg{
        	transform: translateX(2px) scale(1.1);
	    }
	}
`;

const LinkA = styled.a`
    ${themeFont(22)}
    color: ${color.green};
    display: inline-block;
    padding: 5px 25px 5px 0;
	text-decoration: underline;
    svg{
        margin-left: 7px;
    }
    &:hover{
		text-decoration: none;
		svg{
        	transform: translateX(2px) scale(1.1);
	    }
	}
`;

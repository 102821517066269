import { takeEvery, put, select } from "redux-saga/effects";
import Api from "../../services/Api";
import stripSlug from "../../utils/stripSlug";

const api = new Api();

function* getAllArticles() {
    try {
        const { hasAllArticles } = yield select(state => ({
            articles: state.articles,
            hasAllArticles: state.global.hasAllArticles
        }));

        if (hasAllArticles) return;

        const response = yield api.fetcher("getAllArticles");

        yield put({
            type: "articles/set",
            payload: response.articles
        });
        yield put({
            type: "global/update",
            payload: response.global
        });
        yield put({
            type: "global/update",
            payload: { hasAllArticles: true }
        });
    } catch (e) {
        yield put({
            type: "site/get-articles/failed"
        });
    }
}

function* fetchSlug(action) {
    try {
        const slug = stripSlug(action.payload);

        // Check if we have the article cached in redux
        let article = yield select(state => state.articles[slug]);
        // If we have the article we dont do anything
        if (article) {
            yield put({
                type: "article/found-in-cache",
                article
            });
            yield put({
                type: "global/update",
                payload: {
                    current: slug
                }
            });
            return;
        }

        // Check if its an event

        let articleSlug = slug;
        let type = "article";

        // Check if we need to fetch the globals as well
        const { getGlobals } = yield select(state => ({
            getGlobals: !state.global.hasGlobals
        }));
        const response = yield api.getSlug(
            articleSlug,
            type,
            getGlobals
            //fetchEvents
        );
        if (getGlobals && response && response.globals) {
            yield put({
                type: "global/set",
                payload: {
                    ...response.globals,
                    current: slug
                }
            });
        }

        if (response && response.article) {
            yield put({
                type: "article/set",
                payload: {
                    slug,
                    data: response
                }
            });
            yield put({
                type: "global/update",
                payload: {
                    current: slug
                }
            });
        } else {
            yield put({
                type: "article/not-found"
            });
        }
    } catch (e) {
        yield put({
            type: "article/not-found"
        });
    }
}

export default [
    takeEvery("articles/all", getAllArticles),
    takeEvery("article/get-by-slug", fetchSlug)
];

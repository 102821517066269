const defaultState = {
    header: [],
    contact: {},
    hasGlobal: false,
    hasAllProducts: false,
    hasAllRecipes: false
};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "global/set":
            return {
                image: payload.image || [],
                header: payload.header || [],
                footer: payload.footer || null,
                contact: payload.contact || {},
                current: payload.current || null,
                hasGlobals: true
            };

        case "global/update":
            return { ...state, ...payload };
        case "global/clear":
        case "REDUX/CLEAR":
            return { ...defaultState };
        default:
            return state;
    }
};

import { all } from "redux-saga/effects";

import pageSaga from "./pageSaga";
import globalSaga from "./globalSaga";
import productSaga from "./productSaga";
import recipeSaga from "./recipeSaga";
import articleSaga from "./articleSaga";
//IMPORT_SAGAS

export default function*() {
    yield all([
        ...productSaga,
        ...recipeSaga,
        ...pageSaga,
        ...articleSaga,
        ...globalSaga
        //COMBINE_SAGAS
    ]);
}

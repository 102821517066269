import React from "react";
import styled from "styled-components";
import { themeFont, colors } from "../../const";
import { A } from "hookrouter";
import { SanityParser } from "../controllers/SanityParser";
import { Column } from "../../components/Grid";
import Image from "../../components/image";

export default ({ products, allergyFilters, type, category, title }) => {
    return Object.keys(products)
        .filter(key => {
            if (!allergyFilters && !category) return true;
            if (products[key].category.title !== category.title) return false;
            if (products[key].allergies === undefined) return true;

            let test = products[key].allergies.map(allergy => {
                return !allergyFilters[allergy.title];
            });
            return !test.includes(false);
        })
        .map(key => {
            let product = products[key];

            console.log("TYPE: ", type);
            const parsed = SanityParser({
                _type: type,
                ...product
            });

            let baseUrl;

            switch (type) {
                case "product":
                    baseUrl = "/produkter/";
                    break;
                case "recipe":
                    baseUrl = "/oppskrifter/";
                    break;
                default:
                    baseUrl = "/";
                    break;
            }

            console.log("PARSED: ", parsed);

            return (
                <Column
                    span={4}
                    mobileSpan={12}
                    gutterWidth={0}
                    key={product._id}
                    noMinWidth={true}
                >
                    <Product href={baseUrl + parsed.link} className={type}>
                        <ImageWrapper>
                            <Image
                                alt={parsed.title || ""}
                                src={parsed.image}
                                source={parsed.imageObj}
                                width={434}
                            />
                        </ImageWrapper>
                        <ProductInfo className={type}>
                            {type === "recipe" && (
                                <>
                                    <p className="recipe-category">
                                        {parsed.category}
                                    </p>
                                    <p className="recipe-title">
                                        {parsed.title}
                                    </p>
                                </>
                            )}
                            {type === "article" && (
                                <>
                                    <p className="article-title">
                                        {parsed.title}
                                    </p>
                                </>
                            )}
                            {type === "product" && (
                                <>
                                    <p className="product-category">
                                        {parsed.category}
                                    </p>
                                    <p className="product-name">
                                        {parsed.product_name}
                                    </p>
                                    <p className="product-weight">
                                        {parsed.weight}
                                    </p>
                                </>
                            )}
                        </ProductInfo>
                    </Product>
                </Column>
            );
        });
};

const Product = styled(A)`
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;
    margin-bottom: 50px;
    position: relative;
    &:hover {
        p.product-name,
        p.article-title,
        p.recipe-title {
            text-decoration: none;
        }
    }
    &.recipe {
        max-width: 346px;
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: ${colors.beige};
            bottom: -35px;
            right: -18px;
            padding-right: 10px;
            z-index: -1;
            margin-bottom: 14px;
        }
        @media (max-width: 768px) {
            max-width: unset;
            width: 100%;
            img {
                object-fit: cover;
            }
        }
    }

    &.product {
        img {
            max-width: 275px;
            object-fit: contain;
        }
    }

    &.article {
        img {
            object-fit: cover;
        }
    }
`;
const ProductInfo = styled.div`
    max-width: 325px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 32px;
    &.recipe {
        padding: 12px 0 0 26px;
    }
    &.article {
        padding: 0;
    }
    p {
        color: ${colors.darkGrey};
        margin-block-start: 0;
        margin-block-end: 0;
        &.product-name {
            ${themeFont(24)};
            text-decoration: underline;
        }
        &.product-category {
            ${themeFont(18)};
        }
        &.recipe-title,
        &.article-title {
            ${themeFont(24)}
            text-decoration: underline;
        }
        &.recipe-category {
            ${themeFont(18)}
        }
    }
    @media (max-width: 768px) {
        &.recipe {
            margin-top: 12px;
        }
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 235px;
    img {
        width: 100%;
        @media (max-width: 768px) {
            max-width: unset;
        }
    }
`;

import React, { useRef } from "react";
import styled from "styled-components";
import { themeFont, colors } from "../const";
import { LinkButton } from "./partials/LinkButton";
import { useInView } from "react-hook-inview";
import { Video } from "./partials/Video";
import useAnchor from "../hooks/useAnchor";
import useScrollTag from "../hooks/useScrollTag";
import useSlugify from "../hooks/useSlugify";

const VideoAndText = ({
    link,
    text,
    title,
    layout,
    backgroundColor,
    background_bottom,
    reverse,
    anchor,
    videoUrl,
    isBackgroundVideo,
    isVertical
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const refs = useRef([]);
    const fallbackAnchor = useSlugify(title ? title : "");
    useAnchor(refs);
    let anchorTag = anchor || fallbackAnchor;
    useScrollTag(refs, anchorTag);

    return (
        <Container
            className={
                "module module-event-list " +
                (isVisible ? "in-view " : "") +
                (background_bottom ? "bottom " : "")
            }
            ref={ref}
        >
            <AnchorTarget ref={el => (refs.current[anchorTag] = el)} />
            <ContentWrapper reverse={reverse} id={anchorTag}>
                {(title || text || link) && (
                    <TextBlock layout={layout}>
                        <div className="text-aligner">
                            {title && (
                                <h2
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                            )}
                            {text}
                            {link && LinkButton(link)}
                        </div>
                    </TextBlock>
                )}

                <VideoBlock layout={layout} backgroundColor={backgroundColor}>
                    {videoUrl && (
                        <Video
                            url={videoUrl}
                            isBackgroundVideo={isBackgroundVideo}
                            isVertical={isVertical}
                        />
                    )}
                    {backgroundColor && (
                        <BackgroundBlock
                            bottom={background_bottom}
                            reverse={reverse}
                            isVertical={isVertical}
                        />
                    )}
                </VideoBlock>
            </ContentWrapper>
        </Container>
    );
};

export default VideoAndText;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &.bottom {
        padding-bottom: 80px;
    }
`;

const TextBlock = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	z-index: 1;
	flex: ${({ layout }) => (layout === "50/50" ? "50" : "65")};
	h2, p{
		max-width: 400px;
	}
    h2{
        ${themeFont(52)}
        color: ${colors.black};
        margin: 0 0 20px 0;
    }
    p{
        margin: 0;
        padding: 0;
        ${themeFont(18)}
        color: ${colors.black};
		span.large_text{
			${themeFont(30)};
		}
		a{
			color: ${colors.green};
	    }
    }
    >a{
        margin-top: 20px;
    }
	@media (max-width: 1080px) {
		justify-content: center;
		margin-bottom: 25px;
	}
	@media (max-width: 768px) {
		margin: 0 25px 25px;
		h2{
			margin-top: 25px;
			${themeFont(45)}
		}
		p{
			${themeFont(18)}
			line-height: 25px;
			span.large_text{
				${themeFont(42)};
			}
		}
	}
`;

const VideoBlock = styled.div`
    flex: ${({ layout }) => (layout === "50/50" ? "50" : "65")};
    height: 100%;
    display: flex;
    @media (max-width: 768px) {
        ${({ backgroundColor }) =>
            backgroundColor ? "margin: 0 25px 25px;" : ""}
    }
`;

const ContentWrapper = styled.div`
    ${props => {
        if (props.reverse) return "flex-direction: row-reverse;";
    }}
    z-index: 1;
    display: flex;
    width: 100%;
    max-width: 1366px;
    margin: 0 82px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 1080px) {
        justify-content: center;
    }
    @media (max-width: 768px) {
        margin: 0;
    }
`;

const AnchorTarget = styled.div`
    position: absolute;
    top: -130px;
`;

const BackgroundBlock = styled.div`
	${({ reverse }) =>
        reverse
            ? `
		right: 35%;
	`
            : `
		left: 30%;
	`}
	${({ bottom }) =>
        bottom
            ? `
		bottom: -80px;
		right: -15%;
		max-height: calc(100% * 0.75);
	`
            : `
		top: 0;
		max-height: calc(100% * 0.55);
	`}
    ${({ isVertical }) =>
        isVertical &&
        `
        display: none;
	    `}
	background-color: ${colors.brown};
    position: absolute;
    width: calc(100% * 0.84);
	height: 100%;
	z-index: 0;
`;

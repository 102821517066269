import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import usePage from "../hooks/usePage";
import stripSlug from "../utils/stripSlug";
import ModuleList from "../modules/ModuleList";
import ArticleModuleList from "../modules/articleModules/ArticleModuleList";

const Page = ({ slug, content }) => {
    usePage(decodeURI(slug));
    if (!content) return null;
    return (
        <Container>
            {{
                page: <ModuleList modules={content.page.modules} />,
                article: <ArticleModuleList modules={content.page.modules} />
            }[content.page._type] || (
                <div style={{ flex: 1, textAlign: "center" }}>
                    {" "}
                    <p>Oops, looks like there's no page here.</p>{" "}
                </div>
            )}
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            content: state.pages[stripSlug(decodeURI(ownProps.slug))]
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(Page);

const Container = styled.div`
    width: 100%;

    &:after {
        content: "";
        clear: both;
        display: table;
    }

    > .module {
        margin-bottom: 80px;
    }
`;

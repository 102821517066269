import React from "react";
import styled from "styled-components";
import { SanityParser } from "../controllers/SanityParser";
import ArticleImage from "./ArticleImage";
import ArticleIntroImageAndText from "./ArticleIntroImageAndText";
import ArticleImageAndText from "./ArticleImageAndText";
import RichTextAndSidebar from "../RichTextAndSidebar";
import TextAndText from "../TextAndText";

const ArticleModuleList = ({ modules }) => {
    if (!modules || !modules.length) return null;
    return (
        <Container>
            {modules.map((module, index) => {
                const parsed = SanityParser(module);
                switch (module._type) {
                    case "imageAndText":
                        return <ArticleImageAndText key={index} {...parsed} />;
                    case "introImageAndText":
                        return (
                            <ArticleIntroImageAndText key={index} {...parsed} />
                        );
                    case "richTextAndSidebar":
                        return <RichTextAndSidebar key={index} {...parsed} />;
                    case "textAndText":
                        return <TextAndText key={index} {...parsed} />;
                    case "articleImage":
                        return <ArticleImage key={index} {...parsed} />;
                    default:
                        console.log(
                            "Missed all conditions for module parsing in article"
                        );
                        return null;
                }
            })}
        </Container>
    );
};

export default ArticleModuleList;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .module {
        margin-bottom: 80px;
        @media (max-width: 768px) {
            margin-bottom: 25px;
        }
    }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { SanityParser } from "../../modules/controllers/SanityParser";
import { colors, themeFont } from "../../const";
import SearchIcon from "../svg/icons/SearchIcon";
import { A } from "hookrouter";
import Search from "../../modules/Search";
import useKey from "../../hooks/useKey";
import Image from "../image";

const Header = ({
    slug,
    menuItems,
    image,
    menuState,
    setMenuState,
    animateFadeOut,
    direction
}) => {
    const [searchState, setSearchState] = useState(false);
    const escape = useKey("escape");

    if (!menuItems) return <div>Loading...</div>;

    const parsed = SanityParser({
        _type: "menu",
        menuItems: menuItems,
        image: image
    });

    if (escape && searchState) setSearchState(false);

    return (
        <Container
            className={
                (!menuState && "scrolling-" + direction) +
                (animateFadeOut ? " animate-fadeout " : "") +
                (menuState ? " menu-open" : "")
            }
        >
            <Wrapper>
                <div className="width-restrictor">
                    <LogoWrapper>
                        <Logo
                            className={menuState ? "menu-open" : ""}
                            href="/"
                            onClick={() => searchState && setSearchState(false)}
                        >
                            <Image
                                alt="logo"
                                src={parsed.image}
                                source={parsed.imageObj}
                                width={300}
                                quality={25}
                            />
                        </Logo>
                    </LogoWrapper>
                    <Menu className={menuState && "open"}>
                        <ul>
                            {parsed.links.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <A
                                            className={
                                                slug === item.url
                                                    ? "active"
                                                    : ""
                                            }
                                            href={"/" + item.url}
                                            onClick={() => {
                                                menuState &&
                                                    setMenuState(false);
                                                searchState &&
                                                    setSearchState(false);
                                            }}
                                        >
                                            {item.name}
                                        </A>
                                    </li>
                                );
                            })}
                            <li className="search-li">
                                <SearchIcon
                                    className="search-icon"
                                    onClick={() => setSearchState(!searchState)}
                                />
                                <MobileSearchWrapper
                                    className={menuState && "open"}
                                >
                                    <Search
                                        closeSearchBar={() =>
                                            setSearchState(false)
                                        }
                                        isOpen={menuState}
                                        setMenuState={setMenuState}
                                    />
                                </MobileSearchWrapper>
                            </li>
                        </ul>
                    </Menu>
                    <Hamburger
                        className={menuState && "open"}
                        onClick={() => setMenuState(!menuState)}
                    />
                </div>
            </Wrapper>
            <SearchWrapper className={searchState && "open"}>
                <Search
                    closeSearchBar={() => setSearchState(false)}
                    isOpen={searchState}
                    setMenuState={setMenuState}
                />
            </SearchWrapper>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            image: state.global.image,
            menuItems: state.global.header.menu,
            slug: state.global.current
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(Header);

const Container = styled.div`
    ${themeFont(20)}
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 99;
    &.scrolling-up {
        &.animate-fadeout {
            position: fixed;
            top: 0;
            left: 0;
            transition: top 0.4s ease-out;
        }
    }
    &.scrolling-down {
        &.animate-fadeout {
            position: fixed;
            top: -100%;
            transition: top 0.5s ease-in;
        }
    }
    .width-restrictor {
        display: flex;
        width: 100%;
        max-width: 1366px;
        margin: 0 82px;
    }
    @media (max-width: 768px) {
        &.menu-open {
            position: absolute;
        }
        .width-restrictor {
            margin: 0 25px 0 25px;
        }
    }
`;

const MobileSearchWrapper = styled.div`
    display: none;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        background-color: ${colors.beige};
        justify-content: center;
        position: absolute;
        top: 0;
        z-index: -1;
        transition: top 0.15s ease-in;
        transition: none;
        &.open {
            z-index: 9999;
            padding: 25px 0;
            position: relative;
        }
    }
`;

const SearchWrapper = styled.div`
    width: 100%;
    display: flex;
    background-color: ${colors.beige};
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: -1;
    transition: top 0.15s ease-in;
    &.open {
        top: 100%;
        padding: 25px 0;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;

const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const LogoWrapper = styled.div`
    display: flex;
    flex: 1;
`;

const Logo = styled(A)`
    justify-self: flex-start;
    max-height: 126px;
    &.menu-open {
        z-index: 999;
    }
    img {
        width: 220px;
    }
    img:hover {
        transform: scale(1.005);
    }
`;
const Menu = styled.nav`
    display: flex;
    ul {
        flex: 0 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
            list-style: none;
            flex: 0;
            margin-left: 38px;
            &.search-li {
                display: flex;
                align-items: center;
                cursor: pointer;
                .search-icon {
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
            a {
                flex: 0;
                white-space: nowrap;
                text-align: right;
                text-decoration: none;
                color: ${colors.lightGrey};
                &.active,
                &:hover {
                    color: ${colors.orange};
                }
            }
        }
    }
    @media (max-width: 965px) {
        &:not(.open) {
            display: none;
        }
        &.open {
            top: 0;
            left: 0;
            display: flex;
            position: absolute;
            width: 100vw;
            height: 100vh;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: ${colors.brown};
            z-index: 100;
            ul {
                display: flex;
                flex-direction: column;
                padding-inline-start: 0;
                ${themeFont(32)}
                li {
                    margin-bottom: 25px;
                    margin-left: 0;
                }
            }
        }
    }
`;

const Hamburger = styled(props => (
    <button {...props}>
        <div />
    </button>
))`
    @media (min-width: 965px) {
        display: none;
    }
    z-index: 200;
    outline: none;
    transform: scale(1.25);
    margin-top: 50px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    padding: 10px;
    background: none;
    box-shadow: none;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    div {
        position: relative;
        width: 20px;
        height: 3px;
        background-color: ${colors.black};
        &:before,
        &:after {
            content: " ";
            width: 20px;
            height: 3px;
            display: block;
            background-color: ${colors.black};
            position: absolute;
        }
        &:after {
            bottom: -6px;
        }
        &:before {
            top: -6px;
        }
    }

    &.open {
        div {
            width: 20px;
            height: 20px;
            background-color: transparent;
            transform: rotate(45deg);
            &:before {
                top: 50%;
                margin-top: -1px;
                left: 0;
                width: 22px;
            }

            &:after {
                width: 3px;
                height: 22px;
                top: 0;
                left: 50%;
                bottom: auto;
                margin-left: -1px;
            }
        }
    }
    @media (max-width: 965px) {
        margin-top: 0;
        align-self: center;
    }
`;

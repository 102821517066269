import React from "react";
import styled from "styled-components";
import { themeFont, colors } from "../../const";

import { connect } from "react-redux";
import { SanityParser } from "../../modules/controllers/SanityParser";

import { A } from "hookrouter";
import Image from "../image";

const Footer = ({ image, footerItem, contactItem }) => {
    if (!footerItem) return <div>Loading...</div>;
    const parsed = SanityParser({
        _type: "footer",
        footer: footerItem,
        contact: contactItem,
        image: image
    });
    return (
        <Container>
            <ContentWrapper>
                <LogoWrapper>
                    <Logo href={"/"}>
                        <Image
                            alt="logo"
                            src={parsed.image}
                            source={parsed.imageObj}
                            width={300}
                            quality={25}
                        />
                    </Logo>
                </LogoWrapper>
                <InfoWrapper>
                    <Nav>
                        <ul>
                            {parsed.nav_left.map((item, index) => {
                                return (
                                    <li key={index}>
                                        {item.external ? (
                                            <a
                                                href={item.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {item.name}
                                            </a>
                                        ) : (
                                            <A href={"/" + item.url}>
                                                {item.name}
                                            </A>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </Nav>
                    <Nav>
                        <ul className="right">
                            {parsed.nav_right.map((item, index) => {
                                return (
                                    <li key={index}>
                                        {item.external ? (
                                            <a
                                                href={item.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {item.name}
                                            </a>
                                        ) : (
                                            <A href={"/" + item.url}>
                                                {item.name}
                                            </A>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </Nav>

                    <ContactWrapper>
                        <h3>Kontakt</h3>
                        <div>Telefon: {parsed.phone}</div>
                        <div>{parsed.email}</div>
                        <pre>{parsed.address}</pre>
                    </ContactWrapper>
                </InfoWrapper>
            </ContentWrapper>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            image: state.global.image,
            footerItem: state.global.footer,
            contactItem: state.global.contact
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(Footer);

const Container = styled.footer`
    display: flex;
    justify-content: center;
    min-height: 304px;
    background-color: ${colors.brown};
    width: 100%;
`;
const ContentWrapper = styled.div`
    max-width: 1366px;
    margin: 0 82px;
    margin-top: 70px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    @media (max-width: 768px) {
        margin: 70px 25px 0;
        flex-direction: column;
        align-items: center;
    }
`;
const LogoWrapper = styled.div`
    flex: 3;
    @media (max-width: 768px) {
        flex: 1;
    }
`;
const Logo = styled(A)`
    img {
        width: 165px;
    }
    img:hover {
        transform: scale(1.005);
    }
`;

const InfoWrapper = styled.div`
    flex: 7;
    display: flex;
    flex-wrap: wrap;
`;
const Nav = styled.nav`
    display: flex;
	flex: 1;
	ul{
		padding-inline-start: 0;
		display: flex;
	    flex-direction: column;
	    align-items: flex-start;
	    justify-content: flex-start;
		li{
	        list-style: none;
			margin-bottom: 8px;
	        a{
	            ${themeFont(18)}
	            color: ${colors.darkGrey};
	            text-decoration: none;
				&:hover{
					color: ${colors.orange};
				}
	        }
	    }
	}
    margin-bottom: 50px;
	@media (max-width: 768px) {
		flex-basis: 50%;
        ul {
            flex: 1;

            &.right {
                align-items: flex-end;
            }
        }
	}
`;
const ContactWrapper = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    h3,
    div,
    pre {
        margin: 0;
        color: ${colors.darkGrey};
    }
    h3 {
        ${themeFont(30)}
        margin-bottom: 20px;
    }

    a {
        color: ${colors.darkGrey};
        text-decoration: none;
        &:hover {
            color: ${colors.orange};
        }
    }

    pre,
    div {
        ${themeFont(20)}
    }
    pre {
        margin-top: 15px;
    }
    padding-bottom: 50px;
`;

const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "products/set":
            let data = [];
            action.payload.forEach(product => {
                if (product.slug) data[product.slug.current] = product;
            });
            return { ...state, ...data };
        case "products/update":
            return { ...state, ...payload };
        case "products/clear":
        case "REDUX/CLEAR":
            return { ...defaultState };
        default:
            return state;
    }
};

import React, { useRef } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { themeFont, colors as color, colors } from "../const";
import { LinkButton } from "./partials/LinkButton";
import { useInView } from "react-hook-inview";
import useScrollTag from "../hooks/useScrollTag";
import useSlugify from "../hooks/useSlugify";

const BackgroundImageAndVideo = ({
    media_image,
    image,
    link,
    text,
    title,
    video,
    anchor
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const refs = useRef([]);
    const fallbackAnchor = useSlugify(title);
    let anchorTag = anchor || fallbackAnchor;

    useScrollTag(refs, anchorTag);

    return (
        <Container
            className={
                "module module-event-list " + (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper
                id={anchorTag}
                ref={el => (refs.current[anchorTag] = el)}
            >
                <BackgroundWrapper image={image} />
                <Left>
                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    {text}
                    {link && LinkButton(link)}
                </Left>
                <Right>
                    {media_image && (
                        <ImageWrapper>
                            <img alt={"Media"} src={media_image} />
                        </ImageWrapper>
                    )}
                    {video && video.vimeo && (
                        <Video
                            url={video.vimeo}
                            height={"500px"}
                            width={"400px"}
                            loop
                            muted
                            controls={false}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        controls: false,
                                        background: true,
                                        byline: 0,
                                        title: 0,
                                        loop: true
                                    },
                                    preloading: true
                                }
                            }}
                        />
                    )}
                </Right>
            </ContentWrapper>
        </Container>
    );
};

export default BackgroundImageAndVideo;

const Container = styled.div`
    position: relative;
    background-color: ${colors.brown};
    display: flex;
    justify-content: center;
`;

const ContentWrapper = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    max-width: 1366px;
    margin: 0 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 55px;
    padding-top: 55px;
`;
const Left = styled.div`
    flex: 1;
    align-self: flex-start;
    margin-top: 90px;
    margin-right: 50px;


    h2{
        ${themeFont(60)}
        color: ${color.darkGrey}
        margin: 0 0 20px 0;
    }
    p{
        margin: 0;
        padding: 0;
        ${themeFont(30)}
        color: ${color.lightGrey}
    }
    >a{
        margin-top: 20px;
    }
    @media(max-width: 900px){
        h2{
            font-size: 5.5vw;
            line-height: 5.5vw;
        }
        p{
            font-size: 3.5vw;
            line-height: 3.5vw;
        }
        a{
            font-size: 3vw;
            line-height: 3vw;
        }

    }
`;
const Right = styled.div`
    flex: 1;
    max-height: 500px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const Video = styled(ReactPlayer)`
    height: 500px;
`;

const BackgroundWrapper = styled.div`
    ${props => props.image && `background-image: url(${props.image});`}
    background-repeat: no-repeat;
    background-position: left center;

    position: absolute;
    top: 34.3px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    @media (max-width: 800px) {
        display: none;
    }
`;
const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        flex: 0;
        height: auto;
        width: 35vw;
        max-width: 350px;
    }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { themeFont, colors } from "../const";
import { SanityParser } from "./controllers/SanityParser";
import { useInView } from "react-hook-inview";

const PeopleGrid = ({ text, title, people, backgroundColor }) => {
    const [gridLimitState] = useState(6);
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });

    return (
        <Container
            backgroundColor={backgroundColor}
            className={
                "module module-event-list " + (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper>
                <IntroWrapper>
                    <h2>{title}</h2>
                </IntroWrapper>
                <PeopleWrapper>
                    {!people && <div>Loading products...</div>}
                    {!!people &&
                        Object.keys(people)
                            .map(key => {
                                const person = people[key];
                                const parsed = SanityParser({
                                    _type: "person",
                                    ...person
                                });
                                return (
                                    <Person key={person._id}>
                                        <InfoWrapper>
                                            <div>{person.name}</div>
                                            <div>{person.title}</div>
                                            <div>{person.email}</div>
                                            <div>{person.phone}</div>
                                        </InfoWrapper>
                                        <ImageWrapper>
                                            <img
                                                alt={title}
                                                src={parsed.image}
                                            />
                                        </ImageWrapper>
                                    </Person>
                                );
                            })
                            .slice(0, gridLimitState)}
                </PeopleWrapper>
            </ContentWrapper>
        </Container>
    );
};

export default PeopleGrid;

const Container = styled.div`
    ${props =>
        props.backgroundColor ? `background-color: ${colors.brown};` : ""}
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    margin-top: 55px;
    flex-wrap: wrap;
`;
const ContentWrapper = styled.div`
    z-index: 1;
    height: 100%;
    max-width: 1366px;
    margin: 0 82px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
`;
const IntroWrapper = styled.div`
    flex: 3;
    margin-top: 50px;
    margin-right: 50px;
    h2 {
        margin-top: 0;
        ${themeFont(42)};
        color: ${colors.red};
    }
`;
const PeopleWrapper = styled.div`
    flex: 7;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;
const Person = styled.div`
    min-width: 350px;
    text-decoration: none;
    flex: 1;
    display: flex;
    align-items: center;

    max-width: calc(100% / 2);
    margin-bottom: 50px;
`;
const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div {
        color: ${colors.darkGrey};
    }
    div:nth-child(1) {
        ${themeFont(20)}
        font-weight: 700;
    }
    div:nth-child(n + 2) {
        ${themeFont(20)}
    }
    div:nth-child(3) {
        color: ${colors.darkGrey};
    }
`;
const ImageWrapper = styled.div`
    align-self: center;
    margin: 15px;
    img {
        width: 100%;
        height: auto;
    }
`;

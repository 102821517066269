const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "category/set":
            return { ...state, ...payload };
        case "category/update":
            return { ...state, ...payload };
        case "category/clear":
        case "REDUX/CLEAR":
            return { ...defaultState };
        default:
            return state;
    }
};

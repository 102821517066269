import React from "react";
import styled from "styled-components";
import { SanityParser } from "./controllers/SanityParser";
import BackgroundImageAndVideo from "./BackgroundImageAndVideo";
import ImageAndText from "./ImageAndText";
import VideoAndText from "./VideoAndText";
import ProductGrid from "./ProductGrid";
import IntroImageAndText from "./IntroImageAndText";
import RichTextAndSidebar from "./RichTextAndSidebar";
import ItemFilter from "./ItemFilter";
import PeopleGrid from "./PeopleGrid";
import ImageLinkAndSidebar from "./ImageLinkAndSidebar";
import Form from "./Form";

const ModuleList = ({ modules }) => {
    if (!modules || !modules.length) return null;

    return (
        <Container>
            {modules.map((module, index) => {
                const parsed = SanityParser(module);

                switch (module._type) {
                    case "backgroundImageAndVideo":
                        return (
                            <BackgroundImageAndVideo key={index} {...parsed} />
                        );
                    case "imageAndText":
                        return <ImageAndText key={index} {...parsed} />;
                    case "videoAndText":
                        return <VideoAndText key={index} {...parsed} />;
                    case "introImageAndText":
                        return <IntroImageAndText key={index} {...parsed} />;
                    case "richTextAndSidebar":
                        return <RichTextAndSidebar key={index} {...parsed} />;
                    case "productGrid":
                        return <ProductGrid key={index} {...parsed} />;
                    case "productFilter":
                        return (
                            <ItemFilter
                                type={"product"}
                                key={index}
                                {...parsed}
                            />
                        );
                    case "peopleGrid":
                        return <PeopleGrid key={index} {...parsed} />;
                    case "recipeFilter":
                        return (
                            <ItemFilter
                                type={"recipe"}
                                key={index}
                                {...parsed}
                            />
                        );
                    case "imageLinkAndSidebar":
                        return <ImageLinkAndSidebar key={index} {...parsed} />;
                    case "form":
                        return <Form key={index} {...parsed} />;
                    default:
                        return null;
                }
            })}
        </Container>
    );
};

export default ModuleList;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .in-view {
        margin-bottom: 80px;
        @media (max-width: 768px) {
            margin-bottom: 25px;
        }
    }
    > div:nth-child(n + 2) {
        & {
            transform: translateY(75px);
            transition: transform 0.5s ease-in;
        }

        &.in-view {
            transform: translateY(0);
            transition: transform 0.5s ease-in;
        }
        &.no-transition {
            transform: translateY(0);
            transition: none;
        }
    }
`;

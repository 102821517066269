import { combineReducers } from "redux";
import pages from "./reducers/pageReducer";
import global from "./reducers/globalReducer";
import product from "./reducers/productReducer";
import category from "./reducers/categoryReducer";
import allergy from "./reducers/allergyReducer";
import recipe from "./reducers/recipeReducer";
import articles from "./reducers/articleReducer";
//REDUCER_IMPORTS

const reducers = combineReducers({
    pages,
    global,
    product,
    category,
    allergy,
    recipe,
    articles
    //REDUCER_COMBINES
});

export default reducers;

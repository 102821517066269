import React, { useRef } from "react";
import styled from "styled-components";
import { themeFont, colors } from "../const";
import BlockContent from "@sanity/block-content-to-react";
import { useInView } from "react-hook-inview";
import useScrollTag from "../hooks/useScrollTag";
import useSlugify from "../hooks/useSlugify";

const TextAndText = ({
    image,
    link,
    text,
    title = "",
    layout,
    backgroundColor,
    double,
    secondary_image,
    reverse,
    anchor
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const refs = useRef([]);
    const fallbackAnchor = useSlugify(title);
    let anchorTag = anchor || fallbackAnchor;

    useScrollTag(refs, anchorTag);

    return (
        <Container
            backgroundColor={backgroundColor}
            className={
                "module module-event-list " + (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper
                reverse={reverse}
                id={anchorTag}
                ref={el => (refs.current[anchorTag] = el)}
            >
                {text.map(item => {
                    return (
                        <Text key={item._key}>
                            <h2>{item.title}</h2>
                            <BlockContent blocks={item.text} />
                        </Text>
                    );
                })}
            </ContentWrapper>
        </Container>
    );
};

export default TextAndText;

const Container = styled.div`
    ${props =>
        props.backgroundColor ? `background-color: ${colors.brown};` : ""}
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    @media (max-width: 768px) {
        margin-top: 25px;
    }
`;

const ContentWrapper = styled.div`
    z-index: 1;
    width: 100%;
    display: flex;
    max-width: 1366px;
    margin: 0 82px;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0 25px 0 25px;
    }
`;

const Text = styled.div`
    flex: 1;
    max-width: 50%;
    margin: 0 82px;
    color: ${colors.darkGrey};
    h2 {
        ${themeFont(32)}
    }
    p {
        ${themeFont(18)}
    }
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
`;

import client from "../utils/sanityClient";

export default class Api {
    fetcher = (type, slug = null) => {
        //TODO Check if we need globals
        const getGlobals = true;
        var query = `{`;
        switch (type) {
            case "getAllProducts":
                query += this.getAllProducts;
                break;
            case "getAllRecipes":
                query += this.getAllRecipes;
                break;
            case "getAllArticles":
                query += this.getAllArticles;
                break;
            case "getProductBySlug":
                query += this.getProductBySlug(slug);
                break;
            case "getRecipeBySlug":
                query += this.getRecipeBySlug(slug);
                break;
            default:
                console.log("fetcher not found : ", type);
                return;
        }
        if (getGlobals) query += this.globals;

        return client.fetch(query + "}");
    };

    getProductBySlug = slug => `"products": *[_type == 'product' && slug.current == '${slug}' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
    ...,
    category->,
    allergies[]->{
       title
    },
     productGrid{
        ...,
        products[]->{
            ...,
            category->
        }
    },

    }`;

    getRecipeBySlug = slug =>
        `"recipes": *[_type == 'recipe' && slug.current == '${slug}' && site == "${process.env.REACT_APP_SITE_NAME}"]{...,}`;

    getAllProducts = `"products": *[_type == 'product' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
	    ...,
	    category->,
	    allergies[]->{
	        title,
	    },
	    productGrid{
	    ...,
	    products[]-> {
	        ...,
	        category->
	    },
	},
	} | order(sortindex asc, title asc)`;

    getAllRecipes = `"recipes": *[_type == 'recipe' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
        ...,
        category->,
        allergies[]->{
            title,
        }
    } | order(title asc)`;

    getAllArticles = `"articles": *[_type == 'article' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
        ...
    } | order(title asc)`;

    globals = `
    ,"global":*[_id=="siteSettings_${process.env.REACT_APP_SITE_NAME}"][0]{
            image,
            header{
                menu[]{
                    ...,
                    internalLink->
                },
            },
            footer{
                ...,
                nav_left[]{
                   ...,
                    internalLink->{
                       slug,
                       title
                    }
                },
                nav_right[]{
                    ...,
                    internalLink->{
                       slug,
                       title
                    }
                }
            },
            contact{
                ...,
            }
    }
    `;

    //  <!---- fetcher above this line  -->

    // getPages = () =>
    //     new Promise(async (resolve, reject) => {
    //         try {
    //             const response = await client.fetch(
    //                 `*[_type == "page" && site == "${process.env.REACT_APP_SITE_NAME}"]`
    //             );
    //         } catch (e) {
    //             console.log("error", e);
    //             reject();
    //         }
    //     });

    //  getSiteSettings = () => client.fetch(globalsQuery);

    getAll = () => client.fetch(`*[_type == 'page' || _type == 'article']`);

    modules = `

          ...,
          modules[]{
            ...,
            link[]{
                ...,
                internalLink->
            },
			_type == 'productGrid' => {
				...,
				products[]-> {
					...,
					category->
				},
			},
			_type == 'introImageAndText' => {
			   image_text_link[]{
                   ...,
					_type,
					name,
					internalLink->{
						slug
					}
			   }
		   },
            _type == 'peopleGrid' => {
                ...,
                people[]->
            },
             _type == 'richTextAndSidebar' => {
             text[]{
                ...,
                asset->
            }
           },
            categories[]->{
                ...,
            }
          }

    `;
    getSlug = (slug, getGlobals) => {
        return client.fetch(`{
	       "page": *[ site == "${
               process.env.REACT_APP_SITE_NAME
           }" && slug.current == '${slug}'][0]{
	        ${this.modules}
	       },
        ${
            getGlobals
                ? `"globals":*[_id=="siteSettings_${process.env.REACT_APP_SITE_NAME}"][0]{
            image,
            ...,
            header{
                menu[]{
                    ...,
                    internalLink->
                },
            },
            footer{
                ...,
                nav_left[]{
                   ...,
                    internalLink->{
                       slug,
                       title
                    }
                },
                nav_right[]{
                    ...,
                    internalLink->{
                       slug,
                       title
                    }
                }
            }
        }`
                : ""
        }
        }

    `);
    };

    getSiteSettings = () => {
        return client.fetch(
            `*[_id=="siteSettings_${process.env.REACT_APP_SITE_NAME}"`
        );
    };
}

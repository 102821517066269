import { takeEvery, put, select } from "redux-saga/effects";
import Api from "../../services/Api";
import stripSlug from "../../utils/stripSlug";

const api = new Api();

function* fetchSlug(action) {
    try {
        const slug = stripSlug(action.payload);

        // Check if we have the page cached in redux
        let page = yield select(state => state.pages[slug]);
        // If we have the page we dont do anything
        if (page) {
            yield put({
                type: "page/found-in-cache",
                page
            });
            yield put({
                type: "global/update",
                payload: {
                    current: slug
                }
            });
            return;
        }

        // Check if its an event

        let pageSlug = slug;
        let type = "page";

        // Check if we need to fetch the globals as well
        const { getGlobals } = yield select(state => ({
            getGlobals: !state.global.hasGlobals
        }));
        const response = yield api.getSlug(
            pageSlug,
            type,
            getGlobals
            //fetchEvents
        );
        if (getGlobals && response && response.globals) {
            yield put({
                type: "global/set",
                payload: {
                    ...response.globals,
                    current: slug
                }
            });
        }

        if (response && response.page) {
            yield put({
                type: "page/set",
                payload: {
                    slug,
                    data: response
                }
            });
            yield put({
                type: "global/update",
                payload: {
                    current: slug
                }
            });
        } else {
            yield put({
                type: "page/not-found"
            });
        }
    } catch (e) {
        yield put({
            type: "page/not-found"
        });
    }
}

export default [takeEvery("page/get-by-slug", fetchSlug)];

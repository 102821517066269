import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { SanityParser } from "../modules/controllers/SanityParser";
import useRecipe from "../hooks/useRecipe";
import IntroImageAndText from "../modules/IntroImageAndText";
import { themeFont, colors } from "../const";

const SingleRecipe = ({ Recipe, slug }) => {
    useRecipe(slug);
    let parsed = null;
    if (Recipe) {
        parsed = SanityParser({
            _type: "Recipe",
            ...Recipe
        });
    }
    if (!parsed) return <div>Loading...</div>;
    return (
        <Container>
            <IntroImageAndText
                {...{
                    title: parsed.title,
                    text: parsed.intro,
                    media_image: parsed.largeImage,
                    mediaImageObj: parsed.largeImageObj
                }}
            />
            <ContentWrapper>
                <Left>
                    <aside className="inner-content">
                        <h2>Ingredienser</h2>
                        {parsed.portions && (
                            <p>
                                {parsed.portions} porsjon
                                {parsed.portions > 1 && "er"}
                            </p>
                        )}
                        <IngredientsWrapper>
                            {parsed.ingredients &&
                                parsed.ingredients.map(ingredient => {
                                    return (
                                        <li
                                            dangerouslySetInnerHTML={{
                                                __html: ingredient.ingredient
                                            }}
                                            key={ingredient._key}
                                        />
                                    );
                                })}
                        </IngredientsWrapper>
                    </aside>
                </Left>
                <Right>
                    <h2>Tilberedning</h2>
                    {parsed.preparation}
                </Right>
            </ContentWrapper>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            Recipe: state.recipe[decodeURI(ownProps.slug)]
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(SingleRecipe);

const Container = styled.div`
    padding-top: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    flex-direction: column;
    width: 100%;
    .intro-image-and-text {
        h1 {
            max-width: 801px;
        }
        p {
            ${themeFont(30)};
            color: ${colors.lightGrey};
        }
        img {
            max-height: 621px;
            object-fit: cover;
        }
    }
    @media (max-width: 768px) {
        padding-top: 0;
        .intro-image-and-text {
            h1 {
                ${themeFont(32)}
            }
        }
    }
`;
const ContentWrapper = styled.div`
    position: relative;
    z-index: 1;
    max-width: 1366px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0 82px;
    h2 {
        color: ${colors.black};
        ${themeFont(42)}
    }
    p,
    li {
        color: ${colors.black};
        ${themeFont(20)}
    }
    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0 25px 0 25px;
    }
`;

const Left = styled.div`
    flex: 3;
    margin-top: 37px;
    margin-right: 50px;
    background-color: ${colors.beige};
    h2 {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    aside.inner-content {
        padding: 42px 22px 69px 55px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
        margin-top: 25px;
    }
`;
const Right = styled.div`
    flex: 7;
`;

const IngredientsWrapper = styled.ul`
    margin-top: 5px;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
`;

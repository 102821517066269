import { useEffect, useState } from "react";

const useKey = key => {
    const [pressed, setPressed] = useState(false);

    useEffect(() => {
        const match = event => key.toLowerCase() === event.key.toLowerCase();

        const onDown = event => {
            if (match(event)) setPressed(true);
        };

        const onUp = event => {
            if (match(event)) setPressed(false);
        };

        window.addEventListener("keydown", onDown);
        window.addEventListener("keyup", onUp);
        return () => {
            window.removeEventListener("keydown", onDown);
            window.removeEventListener("keyUp", onUp);
        };
    }, [key]);

    return pressed;
};

export default useKey;

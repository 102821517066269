import React, { useRef } from "react";
import styled from "styled-components";
import { themeFont, colors } from "../const";
import { useInView } from "react-hook-inview";
import useScrollTag from "../hooks/useScrollTag";
import useSlugify from "../hooks/useSlugify";

const RichTextAndSidebar = ({
    image,
    link,
    text,
    title,
    sidebar,
    backgroundColor,
    anchor
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const refs = useRef([]);
    const fallbackAnchor = useSlugify(title);
    let anchorTag = anchor || fallbackAnchor;

    useScrollTag(refs, anchorTag);

    return (
        <Container
            backgroundColor={backgroundColor}
            className={
                "module module-event-list " + (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper
                id={anchorTag}
                ref={el => (refs.current[anchorTag] = el)}
            >
                {sidebar ? (
                    <Content>
                        {title && (
                            <aside>
                                <h2>{title}</h2>
                            </aside>
                        )}
                        <RichText>{text}</RichText>
                    </Content>
                ) : (
                    <ContentNoBar>
                        {title && <h2>{title}</h2>}
                        <RichText>{text}</RichText>
                    </ContentNoBar>
                )}
            </ContentWrapper>
        </Container>
    );
};

export default RichTextAndSidebar;

const Container = styled.div`
    ${props =>
        props.backgroundColor ? `background-color: ${colors.brown};` : ""}
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 82px 55px;
    @media (max-width: 768px) {
        margin: 0 25px 0 25px;
    }
`;

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1366px;
`;

const Content = styled.div`
    display: flex;
    aside {
        flex: 3;
        margin-right: 10%;
        h2 {
            margin-top: 0;
            ${themeFont(42)};
            color: ${colors.black};
        }
    }
    @media (max-width: 768px) {
        flex: 1;
        flex-direction: column;
        aside {
            margin-right: 0;
            h2 {
                margin-bottom: 20px;
            }
        }
    }
`;
const ContentNoBar = styled.div`
    margin: 0 255px;
    h2 {
        margin-top: 0;
        ${themeFont(32)};
        color: ${colors.darkGrey};
    }
    @media (max-width: 1168px) {
        margin: 0 82px;
    }
    @media (max-width: 768px) {
        margin: 0;
    }
`;

const RichText = styled.div`
    margin: 0;
    flex: 7;
    a,
    p {
        margin-top: 0;
        ${themeFont(20)};
    }
    a {
        color: ${colors.black};
    }
    p {
        color: ${colors.black};
    }
    figure {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
`;

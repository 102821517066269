import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { themeFont, colors as color, colors } from "../const";
import BlockContent from "@sanity/block-content-to-react";
import ProductsList from "./partials/ProductsList";
import stripSlug from "../utils/stripSlug";
import connect from "react-redux/es/connect/connect";
import { arrow } from "./partials/LinkButton";
import { dispatch } from "../redux";
import { useInView } from "react-hook-inview";
import { Grid, Row } from "../components/Grid";
import useScrollTag from "../hooks/useScrollTag";

const circleBorder = checked => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
        >
            <g fill="none" stroke="#707070" strokeWidth="2">
                <circle cx="11" cy="11" r="11" stroke="none" />
                {checked && (
                    <circle cx="11" cy="11" r="7" fill={color.lightOrange} />
                )}
                <circle cx="11" cy="11" r="10" fill="none" />
            </g>
        </svg>
    );
};

const ItemFilter = ({
    type,
    text,
    title,
    categoryState,
    category,
    allergies,
    productState,
    backgroundColor,
    categories
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const refs = useRef([]);

    useEffect(() => {
        if (refs && window.location.hash) {
            Object.keys(refs.current).forEach(ref => {
                if (window.location.hash.includes(encodeURI(ref))) {
                    refs.current[ref].scrollIntoView({
                        behaviour: "smooth",
                        block: "start",
                        inline: "center"
                    });
                }
            });
        }
    });

    switch (type) {
        case "product":
            dispatch("products/all");
            break;
        case "recipe":
            dispatch("recipes/all");
            break;
        default:
            console.log("Not sure which item type to fetch");
            break;
    }

    const [categoryFilters, setCategoryFilters] = useState([]);

    const toggleCategory = title => {
        if (categoryFilters.includes(title)) {
            let filteredArray = categoryFilters.filter(a => a !== title);
            setCategoryFilters(filteredArray);
        } else {
            setCategoryFilters(categoryFilters => [...categoryFilters, title]);
        }
    };

    const [allergyFilters, setAllergyFilters] = useState([]);

    const toggleAllergy = title => {
        if (allergyFilters.includes(title)) {
            let filteredArray = allergyFilters.filter(a => a !== title);
            setAllergyFilters(filteredArray);
        } else {
            setAllergyFilters(allergyFilters => [...allergyFilters, title]);
        }
    };
    const [filterToggle, setFilterToggle] = useState([false]);

    const toggleFilters = () => {
        setFilterToggle(!filterToggle);
    };

    return (
        <Container
            backgroundColor={backgroundColor}
            className={
                "module module-event-list " + (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper>
                <FilterWrapper>
                    <FilterButtonWrapper>
                        <FilterButton className={filterToggle && "hidden"}>
                            <div onClick={() => toggleFilters()}>
                                {filterToggle ? "Filtrer på" : "Skjul filter"}{" "}
                                {arrow(colors.green)}
                            </div>
                        </FilterButton>
                    </FilterButtonWrapper>
                    <Filters className={filterToggle && "hidden"}>
                        <Filter>
                            <h3>Kategori</h3>
                            {categoryState && (
                                <ul className="filter-column">
                                    {Object.keys(categoryState).map(key => {
                                        let category = categoryState[key];
                                        return (
                                            <CheckedWrapper
                                                key={key}
                                                onClick={() =>
                                                    toggleCategory(
                                                        category.title
                                                    )
                                                }
                                            >
                                                {circleBorder(
                                                    categoryFilters.includes(
                                                        category.title
                                                    )
                                                )}
                                                <div>{category.title}</div>
                                            </CheckedWrapper>
                                        );
                                    })}
                                </ul>
                            )}
                        </Filter>
                        {allergies && Object.keys(allergies).length > 0 && (
                            <Filter>
                                <h3>Fri for</h3>
                                <ul className="filter-column">
                                    {Object.keys(allergies).map(key => {
                                        let allergy = allergies[key];
                                        return (
                                            <CheckedWrapper
                                                onClick={() =>
                                                    toggleAllergy(allergy)
                                                }
                                                key={key}
                                            >
                                                {circleBorder(
                                                    allergyFilters.includes(
                                                        allergy
                                                    )
                                                )}
                                                <div>{allergy}</div>
                                            </CheckedWrapper>
                                        );
                                    })}
                                </ul>
                            </Filter>
                        )}
                    </Filters>
                </FilterWrapper>
                {categoryState &&
                    Object.keys(categories).map((key, i) => {
                        let category = categories[key];
                        if (
                            categoryFilters.length > 0 &&
                            !categoryFilters.includes(category.title)
                        )
                            return null;

                        return (
                            <CategoryWrapper type={type} key={key}>
                                <Category
                                    ref={el =>
                                        (refs.current[
                                            category?.slug?.current
                                        ] = el)
                                    }
                                    id={category.slug?.current}
                                    className={type}
                                >
                                    <h2>{category.title}</h2>
                                    {category.text && (
                                        <BlockContent blocks={category.text} />
                                    )}
                                    <ProductsWrapper>
                                        <Grid>
                                            <Row>
                                                <ProductsList
                                                    products={productState}
                                                    category={category}
                                                    type={type}
                                                    allergyFilters={
                                                        allergyFilters
                                                    }
                                                    title={title}
                                                />
                                            </Row>
                                        </Grid>
                                    </ProductsWrapper>
                                </Category>
                            </CategoryWrapper>
                        );
                    })}
            </ContentWrapper>
        </Container>
    );
};
const mapStateToProps = (state, ownProps) => {
    switch (ownProps.type) {
        case "product":
            return Object.assign(
                {},
                {
                    content: state.pages[stripSlug(ownProps.slug)],
                    allergies: state.allergy["products"],
                    categoryState: state.category["products"],
                    productState: state.product
                },
                ownProps
            );
        case "recipe":
            return Object.assign(
                {},
                {
                    content: state.pages[stripSlug(ownProps.slug)],
                    allergies: state.allergy["recipes"],
                    categoryState: state.category["recipes"],
                    productState: state.recipe
                },
                ownProps
            );
        default:
            console.log("Error in itemfilter mapStateToProps");
            break;
    }
};
export default connect(mapStateToProps, null)(ItemFilter);

const Container = styled.div`
    ${props =>
        props.backgroundColor ? `background-color: ${colors.brown};` : ""}
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const FilterWrapper = styled.div`
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    > div {
        flex: 1;
        display: flex;
    }
`;
const FilterButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;
const FilterButton = styled.div`
    max-width: 1366px;
    margin: 0 82px;
    user-select: none;
    width: 100%;
    border-bottom: 2px solid ${color.lightGrey};
    display: flex;
    justify-content: center;
    z-index: 3;
    div{
        transform: translateY(50%);
        background-color: white;
        flex: 0 auto;
        cursor: pointer;
        text-decoration: none;
        ${themeFont(22)}
        color: ${color.green};
        border: 1px solid ${color.lightGrey};
        display: inline-block;
        padding: 5px 25px 5px 20px;
        svg{
            margin-left: 7px;
			transform: rotate(90deg);
        }
    }
    &.hidden svg{
		transform: rotate(0deg);
    }
	@media (max-width: 768px) {
		margin: 0 25px 0 25px;
	}
`;

const Filter = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin: 0 25px 25px 55px;
    ${themeFont(18)}
    h3 {
        ${themeFont(32)}
        margin: 25px 0 10px 0;
    }
    cursor: pointer;
    user-select: none;
    transition: all 0.25s;
    ul.filter-column {
        all: unset;
        column-count: 2;
        column-gap: 25px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin: 0 25px 25px;
        ul.filter-column {
            column-gap: unset;
            column-width: auto;
        }
    }
    @media (max-width: 480px) {
        ul.filter-column {
            column-count: unset;
        }
    }
`;
const Filters = styled.div`
    display: flex;
    flex: 0 auto;
    width: 100%;
    opacity: 1;
    transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in;
    height: auto;
    max-height: 300px;
    &.hidden {
        max-height: 0;
        opacity: 0;
        pointer-events: none;
    }
    display: flex;
    justify-content: center;
`;

const CheckedWrapper = styled.li`
    display: flex;
    cursor: pointer;
    max-height: 8rem;
    margin-bottom: 5px;
    svg {
        margin-right: 5px;
    }
`;
const CategoryWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

const Category = styled.div`
    width: 100%;
    max-width: 1366px;
    margin: 0 82px;
    h2 {
        ${themeFont(60)}
        margin-bottom: 15px;
    }
    p {
        ${themeFont(18)}
        max-width: 700px;
    }
    @media (max-width: 768px) {
        h2 {
            margin-bottom: 25px;
        }
        margin: 0 25px 0 25px;
    }
`;

const ProductsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: baseline;
`;

const AnchorTarget = styled.div`
    position: absolute;
    top: -130px;
`;

import React, { useEffect } from "react";
import { useRoutes } from "hookrouter";
import Page from "./templates/Page";
import SingleProduct from "./templates/SingleProduct";
import SingleRecipe from "./templates/SingleRecipe";
import SearchPage from "./templates/SearchPage";

const routes = {
    "/": () => <Page slug="/" />,
    "/oppskrifter/:slug": ({ slug }) => <SingleRecipe slug={slug} />,
    "/produkter/:slug": ({ slug }) => <SingleProduct slug={slug} />,
    "/search/:slug": ({ slug }) => <SearchPage slug={slug} />,
    "/:slug*": ({ slug }) => <Page slug={slug} />
};

const Routes = () => {
    const hookedRoutes = useRoutes(routes);
    useEffect(() => window.scrollTo(0, 0));
    return hookedRoutes || "Not Found";
};

export default Routes;

import React from "react";
import styled from "styled-components";
import { themeFont, colors } from "../const";
import { useInView } from "react-hook-inview";

const Field = ({ field, text }) => {
    switch (field) {
        case "text":
            return <input name={text} type={"text"} />;
        case "textarea":
            return <textarea id={"text"} />;
        case "email":
            return <input name={text} type={"email"} />;
        default:
            console.log("Missed all conditions to return fields in form");
            break;
    }
};
const Form = ({ intro, title, receiver, fields, sidebar, backgroundColor }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });

    return (
        <Container
            backgroundColor={backgroundColor}
            className={
                "module module-event-list " + (isVisible ? "in-view" : "")
            }
            ref={ref}
        >
            <ContentWrapper>
                <h2>Kontaktskjema</h2>
                <Content>
                    <div>
                        <h3>{title}</h3>
                        {intro}
                    </div>
                    <InputForm>
                        {fields.map(field => {
                            return (
                                <Input key={field._key}>
                                    <label>{field.text}</label>
                                    {Field({ ...field })}
                                </Input>
                            );
                        })}
                        <Button>Knapp?</Button>
                    </InputForm>
                </Content>
            </ContentWrapper>
        </Container>
    );
};

export default Form;

const Container = styled.div`
    ${props =>
        props.backgroundColor ? `background-color: ${colors.brown};` : ""}
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 55px;
`;

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1366px;
    display: flex;
    h2 {
        margin-top: 0;
        flex: 30;
        ${themeFont(42)};
        color: ${colors.red};
        margin-left: 55px;
    }
`;

const Content = styled.div`
    display: flex;
    flex: 70;
    flex-direction: column;
    margin-right: 55px;

    >div{
        h3{
            margin-top: 6px;
            ${themeFont(30)}
            color: ${colors.lightGrey};
        }
        p{
            ${themeFont(20)}
            color: ${colors.lightGrey};
        }
    }

`;
const InputForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Input = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    ${themeFont(20)}
    color: ${colors.darkGrey};
    textarea,
    input{
        border: 1px solid ${colors.lightGrey};
    }
    textarea{
        height: 250px;
        min-height: 100px;
        min-width: 100px;
        width: 750px;
        max-width: 80%;
    }
    input{
        margin-bottom : 25px;
        height: 40px;
        width: 261px;
    }
`;

const Button = styled.a`
    ${themeFont(18)}
    background-color: ${colors.red}
    color: white;
    padding: 20px 30px;
    cursor: pointer;
    text-decoration: none;
    max-width: 250px;
    margin: 25px;
    margin-left: 50%;
    transform: translateX(-75%);
    text-align: center;
`;

import React from "react";
import { connect } from "react-redux";
import useProduct from "../hooks/useProduct";
import styled from "styled-components";
import { SanityParser } from "../modules/controllers/SanityParser";
import { themeFont, colors } from "../const";
import ProductGrid from "../modules/ProductGrid";
import Image from "../components/image";

const i = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25.087"
        viewBox="0 0 24 25.087"
    >
        <g id="Group_49" data-name="Group 49" transform="translate(-83 -615)">
            <circle
                id="Ellipse_4"
                data-name="Ellipse 4"
                cx="12"
                cy="12"
                r="12"
                transform="translate(83 615)"
                fill={colors.lightOrange}
            />
            <text
                id="i"
                transform="translate(92.257 633.087)"
                fill={colors.white}
                fontSize="23"
                fontFamily="themeFontPro-Regular, themeFont Pro"
            >
                <tspan x="0" y="0">
                    i
                </tspan>
            </text>
        </g>
    </svg>
);

const SingleProduct = ({ productState, slug }) => {
    useProduct(slug);
    let parsed = null;
    let parsedGrid = null;
    if (!productState) return null;

    if (productState) {
        parsed = SanityParser({
            _type: "product",
            ...productState
        });
        if (productState.productGrid) {
            parsedGrid = SanityParser({
                _type: "productGrid",
                ...productState.productGrid
            });
        }
    }

    console.log("parsed: ", parsed);

    return (
        <Container>
            {parsed && (
                <ContentWrapper>
                    <IntroWrapper>
                        <Text>
                            <div className="background-block">
                                <h1>{parsed.title}</h1>
                            </div>
                            {parsed.intro}
                            <Allergies>
                                <h3>
                                    <span>{i}</span>ALLERGENER
                                </h3>
                                <ul className="allergen-list">
                                    {parsed.allergies &&
                                    parsed.allergies.length > 0 ? (
                                        parsed.allergies.map((item, key) => {
                                            return (
                                                <li key={key}>
                                                    <p>
                                                        {item.title}
                                                        {parsed.allergies
                                                            .length >
                                                        key + 1
                                                            ? ","
                                                            : ""}
                                                    </p>
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <li>
                                            <p>Ingen.</p>
                                        </li>
                                    )}
                                </ul>
                            </Allergies>
                        </Text>
                        <ImageWrapper>
                            <Image
                                alt={"Bilde av " + parsed.title}
                                src={parsed.largeImage}
                                source={parsed.largeImageObj}
                            />
                        </ImageWrapper>
                    </IntroWrapper>

                    <ProductInfoWrapper>
                        {parsed.preparation && (
                            <Preparation>
                                <div>
                                    <h2>Tilberedning:</h2>
                                    <div>{parsed.preparation}</div>
                                </div>
                                <ImageWrapper>
                                    {parsed.preparation_image && (
                                        <Image
                                            alt={
                                                "Bilde av tilberedning av " +
                                                parsed.title
                                            }
                                            src={parsed.preparation_image}
                                            source={parsed.preparationImageObj}
                                        />
                                    )}
                                </ImageWrapper>
                            </Preparation>
                        )}
                        <Section hasPrep={parsed.preparation}>
                            <NutritionWrapper>
                                <h2>NÆRINGSINNHOLD</h2>
                                <Energy>
                                    <span>PR. 100G</span>
                                </Energy>
                                <ul className="nutritional-list">
                                    {parsed.nutrition_title && (
                                        <NutritionTitle>
                                            {parsed.nutrition_title}
                                        </NutritionTitle>
                                    )}
                                    <Nutrition key={"key"}>
                                        <span>Energi</span>
                                        <span>{parsed.energy}</span>
                                    </Nutrition>
                                    {parsed.nutritional &&
                                        parsed.nutritional.map((item, key) => (
                                            <Nutrition key={key}>
                                                <span>{item.text} </span>
                                                <span>{item.gram}g</span>
                                            </Nutrition>
                                        ))}
                                </ul>
                                <ul className="nutritional-list">
                                    {parsed.nutrition_title_secondary && (
                                        <NutritionTitle>
                                            {parsed.nutrition_title_secondary}
                                        </NutritionTitle>
                                    )}
                                    {parsed.energy_secondary && (
                                        <Nutrition key={"key"}>
                                            <span>Energi</span>
                                            <span>
                                                {parsed.energy_secondary}
                                            </span>
                                        </Nutrition>
                                    )}
                                    {parsed.nutritional_secondary &&
                                        parsed.nutritional_secondary.map(
                                            (item, key) => (
                                                <Nutrition key={key}>
                                                    <span>{item.text} </span>
                                                    <span>{item.gram}g</span>
                                                </Nutrition>
                                            )
                                        )}
                                </ul>
                            </NutritionWrapper>
                            <IngredientsWrapper>
                                <h2>INGREDIENSER</h2>
                                {parsed.ingredients}
                            </IngredientsWrapper>
                            <ProductInfo>
                                <h2>PRODUKTINFORMASJON</h2>
                                {parsed.information}
                            </ProductInfo>
                        </Section>
                    </ProductInfoWrapper>
                    {parsedGrid && parsedGrid.products.length > 0 && (
                        <ProductGrid {...parsedGrid} />
                    )}
                </ContentWrapper>
            )}
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            productState: state.product[decodeURI(ownProps.slug)]
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(SingleProduct);

const ImageWrapper = styled.div`
    flex: 3;
    align-self: center;
    width: 100%;
    img {
        width: 100%;
        height: auto;
        max-height: 450px;
        object-fit: contain;
    }
`;
const Container = styled.div`
    h2 {
        margin-block-end: 0;
    }
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    min-height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media (max-width: 768px) {
        padding-top: 0;
    }
`;

const ContentWrapper = styled.div`
    position: relative;
    z-index: 1;
    width: calc(100% - 55px);
    height: 100%;
    max-width: 1366px;
    margin: 0 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const IntroWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    ${ImageWrapper} {
        margin: 55px;
    }
    padding-bottom: 55px;
    margin-bottom: 55px;
    border-bottom: 1px solid ${colors.lightGrey};
    @media (max-width: 768px) {
        flex-direction: column;
        ${ImageWrapper} {
            margin: 25px 0 0;
            flex: 1;
        }
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
`;

const Allergies = styled.div`
    ${themeFont(18)}
    color: ${colors.black};
    h3{
        display: flex;
        font-weight: 700;
        margin-bottom: 0;
    }
    span{
        margin-right: 5px;
    }
    ul.allergen-list{
        display: flex;
        margin-left: 30px;
		list-style-type: none;
		margin-block-start: 0;
		padding-inline-start: 0;
        li{
            margin-right: 5px;
			p{
				all: unset;
			}
        }
    }

`;
const Text = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    h1 {
        color: ${colors.white};
        ${themeFont(60)};
    }
    p {
        ${themeFont(18)};
        padding-right: 60px;
    }
    .background-block {
        display: inline-flex;
        background-color: ${colors.lightOrange};
        padding-left: 180px;
        padding-right: 60px;
        margin-left: -180px;
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        order: 2;
        flex: 1;
        .background-block {
            padding: 25px 0;
            margin: 0 -55px;
            h1 {
                margin: 0 55px;
                ${themeFont(45)}
            }
        }
    }
`;

const ProductInfoWrapper = styled.div`
    display: flex;
    width: 100%;
`;

const Preparation = styled.div`
    flex: 4;
    ${themeFont(18)};
    h2 {
        ${themeFont(32)};
        margin-top: 0;
    }
    ${ImageWrapper} {
        margin-top: 55px;
        img {
            width: 100%;
        }
    }
    margin-right: 125px;
`;

const Section = styled.div`
    flex: 2;
    margin-top: 0;
    h2 {
        ${themeFont(30)}
    }
    p {
        ${themeFont(18)}
    }
    ${props => {
        if (!props.hasPrep) {
            return `
	            display: flex;
	            align-self: flex-end;
	            justify-content: space-between;
	            flex-wrap: wrap;
	            ${ProductInfo}, ${IngredientsWrapper}, ${NutritionWrapper}{
	                min-width: 250px;
	                width: 100%;
	                flex: 1;
	                padding-bottom: 75px;
	                h2{
	                    margin-top: 0;
	                }
	                @media (min-width: 768px) {
	                	border: unset;
	                }
					@media (max-width: 768px) {
						min-width: unset;
						padding: 25px 0;
					}
	            }
				${IngredientsWrapper}{
					@media (min-width: 1024px) {
						padding: 0 75px;
					}
					@media (max-width: 768px) {
						padding: 25px 0;
					}
				}
				@media (max-width: 1024px) {
					flex-direction: column;
				}
	        `;
        }
    }}
`;
const Energy = styled.div`
    border-bottom: 1px solid ${colors.lightGrey};
    margin-bottom: 20px;
    padding-bottom: 30px;
`;
const NutritionWrapper = styled.div`
    padding-bottom: 25px;
    ${themeFont(18)}
    color: ${colors.darkGrey};
    h2{
        margin-top: 0;
    }
    border-bottom: 1px solid ${colors.lightGrey};

	ul.nutritional-list{
		all: unset;
		display: flex;
		flex-direction: column;
	}
`;

const Nutrition = styled.li`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    span {
        flex: 1;
    }
    span:last-child {
        text-align: right;
    }
`;

const IngredientsWrapper = styled.div`
    padding-bottom: 25px;
    border-bottom: 1px solid ${colors.lightGrey};
`;

const NutritionTitle = styled.div`
    margin-top: 24px;
    font-weight: bold;
`;

const ProductInfo = styled.div`
    h2 {
        margin-bottom: 15px;
    }
    p {
        line-height: 28px;
        margin-block-start: 0;
        margin-block-end: 0;
    }
    margin-right: 55px;
    @media (max-width: 768px) {
        margin-right: 0;
    }
`;

import React, { useRef } from "react";
import styled from "styled-components";
import { themeFont, colors } from "../const";
import { LinkButton } from "./partials/LinkButton";
import { useInView } from "react-hook-inview";
import getImageDimensionsFromUrl from "../utils/getImageDimensionsFromUrl";
import ReactPlayer from "react-player";
import useScrollTag from "../hooks/useScrollTag";
import useSlugify from "../hooks/useSlugify";
import Image from "../components/image";

const VideoComponent = ({
    width,
    height,
    double,
    background_bottom,
    playerConfig,
    url
}) => {
    return (
        <VideoWrapper
            wideImage={width > height}
            double={double}
            bottom={background_bottom}
        >
            <Video
                url={url}
                height={"100%"}
                width={"100%"}
                config={playerConfig}
            />
        </VideoWrapper>
    );
};

const ImageAndText = ({
    image,
    imageObj,
    link,
    text,
    title,
    layout,
    backgroundColor,
    background_bottom,
    playerBackgrounded,
    double,
    video,
    secondary_image,
    secondaryImageObj,
    reverse,
    large_text,
    anchor
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const { height, width } = getImageDimensionsFromUrl(image);
    const refs = useRef([]);
    const fallbackAnchor = useSlugify(title);
    let anchorTag = anchor || fallbackAnchor;

    useScrollTag(refs, anchorTag);

    const playerConfig = {
        vimeo: {
            playerOptions: {
                controls: !playerBackgrounded,
                autoplay: playerBackgrounded,
                background: playerBackgrounded,
                muted: playerBackgrounded,
                byline: 0,
                title: 0,
                loop: playerBackgrounded
            },
            preloading: true
        }
    };

    return (
        <Container
            className={
                "module module-event-list " +
                (isVisible ? "in-view " : "") +
                (background_bottom ? "bottom " : "")
            }
            ref={ref}
        >
            <AnchorTarget ref={el => (refs.current[anchorTag] = el)} />
            <ContentWrapper reverse={reverse} id={anchorTag}>
                <TextBlock layout={layout}>
                    <div className="text-aligner">
                        {title && (
                            <h2 dangerouslySetInnerHTML={{ __html: title }} />
                        )}
                        {text}
                        {link && LinkButton(link)}
                    </div>
                </TextBlock>
                <ImageBlock layout={layout}>
                    {image && (
                        <ImageWrapper
                            wideImage={width > height}
                            double={double}
                            bottom={background_bottom}
                        >
                            <Image alt={title} src={image} source={imageObj} />
                        </ImageWrapper>
                    )}
                    {video && (
                        <VideoComponent
                            url={video?.name}
                            playerConfig={playerConfig}
                            double={double}
                            background_bottom={background_bottom}
                            width={width}
                            height={height}
                        />
                    )}
                    {double && (
                        <ImageWrapper2>
                            <Image
                                alt={title}
                                src={secondary_image}
                                source={secondaryImageObj}
                            />
                        </ImageWrapper2>
                    )}
                    {backgroundColor && (
                        <BackgroundBlock
                            bottom={background_bottom}
                            reverse={reverse}
                        />
                    )}
                </ImageBlock>
            </ContentWrapper>
        </Container>
    );
};

export default ImageAndText;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &.bottom {
        padding-bottom: 80px;
    }
`;

const ContentWrapper = styled.div`
    ${props => {
        if (props.reverse) return "flex-direction: row-reverse;";
    }}
    z-index: 1;
    display: flex;
    width: 100%;
    max-width: 1366px;
    margin: 0 82px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 1080px) {
        flex-direction: column;
        justify-content: center;
    }
    @media (max-width: 768px) {
        margin: 0;
    }
`;
const TextBlock = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	z-index: 1;
	flex: ${({ layout }) => (layout === "50/50" ? "50" : "65")};
	h2, p{
		max-width: 400px;
	}
    h2{
        ${themeFont(52)}
        color: ${colors.black};
        margin: 0 0 20px 0;
    }
    p{
        margin: 0;
        padding: 0;
        ${themeFont(18)}
        color: ${colors.black};
		span.large_text{
			${themeFont(30)};
		}
		a{
			color: ${colors.green};
	    }
    }
    >a{
        margin-top: 20px;
    }
	@media (max-width: 1080px) {
		justify-content: center;
		margin-bottom: 25px;
	}
	@media (max-width: 768px) {
		margin: 0 25px 25px;
		h2{
			margin-top: 25px;
			${themeFont(45)}
		}
		p{
			${themeFont(18)}
			line-height: 25px;
			span.large_text{
				${themeFont(42)};
			}
		}
	}
`;
const ImageBlock = styled.div`
    flex: ${({ layout }) => (layout === "50/50" ? "50" : "65")};
    min-width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 768px) {
        margin: 0 25px 25px;
        min-width: unset;
    }
`;

const ImageWrapper = styled.div`
    flex: 1;
    display: flex;
    z-index: 1;
    justify-content: center;
    margin-top: ${({ bottom }) => (bottom ? 0 : 30)}px;
    flex-direction: column;
    img {
        ${props =>
            props.double
                ? `
            padding: 10px 10px 0 0 ;
        `
                : ``}
        object-fit: contain;
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    @media (max-width: 768px) {
        margin-top: 25px;
        padding: 0;
        flex-direction: column;
        ${({ wideImage }) => (wideImage ? `max-height: 400px` : ``)}
    }
`;

const VideoWrapper = styled.div`
    flex: 1;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 1;
    padding-top: 56.25%;
    position: relative;
    margin-top: ${({ bottom }) => (bottom ? 0 : 30)}px;
`;

const ImageWrapper2 = styled.div`
    position: absolute;
    top: 0;
    left: 35%;
    width: calc(100% * 0.84);
    height: 100%;
    max-height: calc(100% * 0.55);
    z-index: 0;
    img {
        width: 100%;
        height: auto;
    }
`;

const BackgroundBlock = styled.div`
	${({ reverse }) =>
        reverse
            ? `
		right: 35%;
	`
            : `
		left: 30%;
	`}
	${({ bottom }) =>
        bottom
            ? `
		bottom: -80px;
		right: -15%;
		max-height: calc(100% * 0.75);
	`
            : `
		top: 0;
		max-height: calc(100% * 0.55);
	`}
	background-color: ${colors.brown};
    position: absolute;
    width: calc(100% * 0.84);
	height: 100%;
	z-index: 0;
`;

const Video = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const AnchorTarget = styled.div`
    position: absolute;
    top: -130px;
`;

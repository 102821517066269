import React, { useEffect, useRef } from "react";

const useAnchor = slug => {
    const ref = useRef(null);
    const hash = window.location.hash.replace("#", "");
    useEffect(() => {
        if (ref.current && slug && slug === hash) {
            setTimeout(() => window.scrollTo(0, ref.current.offsetTop), 500);
        }
    }, [ref]);

    return ref;
};

export default useAnchor;

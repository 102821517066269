import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import { eventSystemReduxMiddleware } from "eventsy";

import reducers from "./reducers";

const logger = createLogger({
    level: "info"
});

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
    const store = createStore(
        reducers,
        applyMiddleware(sagaMiddleware, eventSystemReduxMiddleware)
    );

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("./reducers", () => {
                store.replaceReducer(reducers);
            });
        }
    }

    return store;
};

const configuredStore = configureStore();

export default configuredStore;

sagaMiddleware.run(sagas);
